@media (max-width: 444.98px) {

    .login-content h1 {
        font-size: 43px !important;
    }

    .help-banner-content .input-group.mt-4 {
        width: 100%;
        margin: 0 auto;
    }

    .submit-btn {
        font-weight: 400;
        padding: 8px 13px;
    }

    .banner-content {
        padding-top: 95px !important;
    }

    .login-content .login-head-text::before {
        height: 24% !important;
    }

}

@media (max-width: 575.98px) {

    .position-text-box {
        margin-top: 10px !important;
    }

    .integration-box li {
        margin-top: 20px;
    }

    .facebook-connection {
        margin-top: 20px;
    }

    .webinar-sec {
        padding: 69px 10px;
    }

    .form-box {
        padding: 20px 10px;
    }

    .banner-content {
        padding: 40px 0px;
    }

    .banner-content h1 {
        font-size: 36px !important;
    }

    .banner-content h4 {
        font-size: 52px;
    }

    .project-header-name .project-item h4 {
        font-size: 20px;

    }

    .project-header-name .project-conut h6 {
        font-size: 20px;
    }

    .banner-content .watch-demo-btn button {
        padding: 15px 50px;
    }

    .banner-video-project .video-project a span {

        margin-left: 0 !important;
    }



    .banner-video-project .video-project a {
        font-size: 14px;
    }

    .banner-video-project .video-project h5 {
        font-size: 20px;

    }

    .player-box {
        margin-top: 20px;
    }

    .important-topics {
        margin-top: 20px;
    }

    .banner-content p {
        width: 100%;
    }

    .banner-items {
        padding: 40px 0px;
    }

    .dashborad-sec-3 {
        padding: 40px 0px;
    }

    .imgsizeleft {
        margin-top: 30px;
    }

    .dashborad-sec-3-content {
        margin-top: 30px;
    }

    header .dropdown-menu {
        left: 0 !important;
    }

    .video-card {
        margin-top: 30px;
    }

    .login-box {
        padding: 40px 0px;
    }

    .login-form {
        padding: 20px;
        margin-top: 20px;
    }

    .login-form p {
        font-size: 14px;
    }

    .login-form h4 {
        font-size: 17px;
    }

    .login-content h5 {
        font-size: 20px;
    }

    .project-button {
        flex-direction: column !important;
        margin-top: 20px;
        justify-content: center;
    }

    .project-button .project-create-btn {
        margin-top: 20px;
    }

    .project-input-box {
        margin-right: 0px;
    }

    .footer .col-lg-4 {
        display: flex;
        justify-content: center !important;
    }


    .search-select {
        flex-direction: column;
        justify-content: start;
    }

    .search-select .search-box {
        margin-top: 20px;
    }

    .template-name h4 {
        font-size: 14px;
    }

    .template-number h6 {
        font-size: 14px;
    }

    .select-box {
        margin-top: 15px;
    }

    .support-content {
        margin-top: 30px;
    }

    .webiner-content {
        margin-top: 20px;
    }

    .merge-btn {
        margin-bottom: 20px;
    }

    .editor-sec-merge .editor-container .colmn-1 {
        max-width: 32%;
    }

}


@media (max-width: 767.98px) {

    .position-text-box {
        margin-top: 10px !important;
    }

    .imgsizeleft {
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }

    .footer .col-lg-4 {
        display: flex;
        justify-content: center !important;
    }

    .footer .col-lg-4 {
        display: flex;
        justify-content: center !important;
    }

    .player-box {
        margin-top: 20px;
    }

    .important-topics {
        margin-top: 20px;
    }

    .support-content {
        margin-top: 30px;
    }

    .webiner-content {
        margin-top: 20px;
    }

    .banner-content h1 {
        font-size: 63px;
    }

    .imgUp {
        font-size: 13px !important;
    }

}

@media (max-width: 991.98px) {
    .navbar-collapse{
        height: 100vh !important;
        margin-top: 20px;
    }
    .navbar-collapse .navbar-nav{
        gap: 15px;
    }
    .navbar-collapse .dropdown{
        margin-top: 20px;
    }
    header .dropdown-menu{
        left: 48px !important;
    }
    .imgsizeleft {
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }

    .login-form {
        margin-top: 30px;
    }

    .video-card {
        margin-top: 30px;
    }

    .footer .col-lg-4 {
        display: flex;
        justify-content: center !important;
    }

    .footer .col-lg-3 {
        display: flex;
        justify-content: center !important;
    }

    .project-button {
        margin-top: 30px;
    }

    .banner-items {
        padding: 60px 0px;
    }

    .banner-content {
        padding: 60px 0px;
        padding-bottom: 20px;
    }

    .dashborad-sec-3 {
        padding: 30px 0px;
    }

    .position-text-box {
        min-width: 123px !important;
        max-width: 123px !important;
        margin: auto;
    }

    .player-box {
        margin-top: 20px;
    }

    .important-topics {
        margin-top: 20px;
    }

    .support-content {
        margin-top: 30px;
    }

    .login-content {
        padding-top: 25px;
    }

    .login-content h2 {
        margin-left: 26px;
        text-align: left;
    }

    .login-content .t-text {
        margin-left: 23px !important;
    }


    .login-content h1 {
        margin-left: 31px;
        text-align: left;
    }

    .imgUp {
        font-size: 9px;
    }

    .editor-sec-merge .editor-container .colmn {
        max-width: 100% !important;
    }

    .editor-sec-merge .colmn-preview-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

}





@media (max-width: 1199.98px) {
    .login-content h1 {
        font-size: 50px;
        margin-left: 37px;
    }

    .login-content .login-head-text::before {
        position: absolute;
        left: 16px;
        height: 30%;
    }

    .welcome-text {
        margin-left: 29px !important;
        margin-top: -10px;
    }

    .template-name h4 {
        font-size: 18px;
    }

    .position-text-box {
        min-width: 123px;
        max-width: 123px;
    }

    .remove-logo-switch-btn .form-check label {
        font-size: 10px;
    }

    .remove-logo-switch-btn .form-check .form-check-input {
        margin-left: 8px;
        margin-top: 10px !important;
        font-size: 10px !important;
    }

    .dimmer-switch-btn .remove-logo-switch-btn .form-check-input {
        margin-top: 2px;
        margin-left: -30px;
        font-size: 10px !important;
    }

    .integration-box li {
        margin-top: 20px;
    }

    .login-content .t2-text {
        margin-left: 23px;
    }

    .footer .footer-copyright p,
    .footer .footer-menu ul li a {
        font-size: 11px;
    }

    .vineasx-logo img {
        width: 196px;
    }

}



@media (max-width:1300px) {

    .position-text-box {
        min-width: 122px;
        max-width: 122px;
    }


    .remove-logo-switch-btn .form-check .form-check-input {
        margin-left: 8px;

        font-size: 20px;
    }

    .dimmer-switch-btn .remove-logo-switch-btn .form-check-input {

        font-size: 20px;
    }


}

@media (min-width:1400px) {

    .save-render-sec {
        width: 100%;
    }


    .editor-preview {
        width: 100%;
    }

    .render-projectbtn {
        margin-right: 0px;
    }

    .editor-tab-menu {
        width: 100%;
    }

}


@media (min-width:1500px) {
    .container-fluid {
        max-width: 1400px;
    }

    .render-projectbtn {
        margin-right: 0px !important;
    }

    .secarchicone-part #searchimg {
        left: 0%;
    }

    .editor-sec-merge .editor-container .colmn-1 {
        max-width: 28%;
    }

    .editor-sec-merge .editor-container .colmn-2 {
        max-width: 72%;
    }
}




@media (max-width: 1699px) {}


@media screen and (max-width: 1599px) {
    .hero-banner-left h2 {
        font-size: 50px;
    }

    .hero-banner-left h3 {
        font-size: 50px;
    }

    .hero-banner-left p {
        padding-right: 100px;
    }

    .bannerLink-left img {
        width: 50px;
    }

    .videoProject-top h2 {
        font-size: 28px;
    }

    .proView {
        font-size: 24px;
    }

    .videoProject-single-top {
        height: 200px;
    }

    .videoProject-single-awating,
    .videoProject-single-vid {
        height: 100%;
    }

    .featureSec-single h2 {
        font-size: 28px;
    }

    .featureSec-single h6 {
        font-size: 17px;
    }

    .appHeader-nav>ul>li {
        margin-left: 50px;
    }

    .inteTop-single {
        width: 135px;
    }

    .popularVideo h2 {
        font-size: 25px;
    }

    .tabRight {
        padding: 50px;
    }

    .tabLeft {
        padding: 30px;
    }

    h2.tabTitle {
        font-size: 20px;
    }

    .popular-topics-box h5 {
        font-size: 18px;
    }

    .tabCont .nav-link {
        font-size: 14px;
    }

    .tabepaneIn-main {
        padding: 25px;
    }

    .tabepaneIn-cont h3 {
        font-size: 16px;
    }

    .footer-single ul li a {
        font-size: 13px;
    }

    .footer-single p {
        font-size: 13px;
    }

    .appHeader-nav>ul>li>a {
        font-size: 14px;
    }

    .action-nav button span.profileName {
        font-size: 14px;
    }

    .logo {
        width: 170px;
    }

    /* .siteWrap {
        padding-top: 65px;
    } */

    .vidHover-icon {
        width: 40px;
        height: 40px;
        line-height: 42px;
        font-size: 18px;
    }

    .vidHover-single span+span {
        font-size: 12px;
    }

    .manage-block p {
        font-size: 14px;
    }

    .txtEdit-top {
        flex-wrap: wrap;
    }

    .txtEdit-nav:first-child {
        width: 50%;
    }

    .txtEdit-nav:nth-child(2) {
        width: 50%;
    }

    .txtEdit-nav:nth-child(3) {
        width: 100%;
        padding-left: 0;
        padding-top: 10px;
        margin-top: 10px;
        border-left: 0;
        border-top: 1px solid #707070;
    }

    .course-step-img img {
        height: 50px;
    }

    .course-step ul li a {
        height: 54px;
    }

    .course-step ul li a p {
        font-size: 15px;
    }

    .editor-right {
        padding-top: 0;
    }
}


@media screen and (max-width: 1199px) {
    .container {
        max-width: 960px;
    }

    .login-container-right {
        padding-left: 0;
    }

    .login-container-right-main {
        padding: 30px;
    }

    .login-container-left h2 {
        font-size: 42px;
    }

    .appHeader-nav>ul>li {
        margin-left: 25px;
    }

    .logo {
        width: 180px;
    }

    /* .siteWrap {
        padding-top: 70px;
    } */

    .reelsitewrap {
        padding-top: 66px !important;
    }

    .hero-banner-right {
        padding-left: 30px;
    }

    .hero-banner-left h2,
    .hero-banner-left h3 {
        font-size: 40px;
    }

    .bannerLink {
        margin: 15px 0;
        height: 66px;
    }

    .hero-banner {
        height: 480px;
    }

    .videoProject {
        padding: 50px 0;
    }

    .featureSec-left {
        padding: 0 !important;
    }

    .featureSec {
        padding-top: 50px;
    }

    .lessonLink-right {
        padding-left: 0;
    }

    .featureSec-single h2 {
        font-size: 24px;
    }

    .featureSec-single h6 {
        font-size: 15px;
    }

    .inteTop-main ul li {
        margin-bottom: 10px;
    }

    .help-banner {
        height: 330px;
    }

    .row.d-flex.justify-content-center.mt-5 {
        margin-top: 0 !important;
    }

    .row.d-flex.justify-content-center.mt-5 .col-xl-6 {
        margin-top: 10px !important;
    }

    .cont-research-head h2 {
        font-size: 20px;
    }

    .editor-left {
        width: 350px;
        padding: 15px;
        padding-bottom: 40px ;
    }

    .editor-right {
        padding-left: 370px;
    }

    .story-block-single {
        padding: 10px;
    }

    .campaignBar .demoLink {
        font-size: 13px;
    }

    .campaignBar {
        padding: 15px;
    }

    .campaignNav {
        padding-left: 15px;
        padding-right: 15px;
    }

    .campaignNav .nav-link {
        width: 72px;
        margin: 0 2px;
    }

    .campaignNav .nav-link span+span {
        font-size: 11px;
    }

    .roundInp {
        font-size: 13px;
    }

    .canvasEditor {
        max-width: 550px;
    }

    .text-position-dropdown .box {
        padding: 13px 2px !important;
        width: 30px !important;
        height: 30px !important;

    }


}

@media screen and (max-width: 1023px) {
    /* .container {
        max-width: 700px;
    } */

    .full-height-row,
    .login-container {
        min-height: 10px;
    }

    .full-height-container {
        min-height: calc(100vh - 130px);
    }

    .login-container {
        padding-top: 130px;
    }

    .footer-single ul {
        text-align: center;
    }

    .footer-single.reelLogo {
        text-align: center;
    }

    .login-container-left {
        padding-bottom: 50px;
    }

    .footer-single p {
        padding: 10px 0;
    }

    .hero-banner {
        height: auto;
        padding: 50px 0;
    }

    .hero-banner-txt {
        position: static;
    }

    .hero-banner-right {
        padding-left: 0;
    }

    .hero-banner-left {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .appHeader-right {
        position: fixed;
        left: -300px;
        top: 0;
        width: 300px;
        background: #000;
        height: 100vh;
        transition: all 0.5s ease;
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .appHeader-right.act {
        left: 0;
    }

    .appHeader-nav>ul>li {
        display: block;
        margin-left: 0 !important;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
    }

    .appHeader-nav>ul>li>a {
        display: block;
        padding: 15px 15px;
        font-size: 14px;
    }

    .appHeader-action {
        display: block;
        padding: 15px;
    }

    .notification-nav {
        margin-left: 0;
    }

    .action-nav {
        margin-left: 0;
        margin-top: 15px;
    }

    .notification-nav button {
        width: 100%;
    }

    .action-nav .dropdown {
        display: block;
    }

    .action-nav .dropdown-menu {
        position: static !important;
        width: 100%;
        margin: 0 !important;
        transform: none !important;
    }

    .notification-nav .dropdown-menu {
        position: static !important;
        width: 100%;
        margin: 0 !important;
        transform: none !important;
    }

    .action-nav button {
        width: 100% !important;
        border: 1px solid rgba(255, 255, 255, .1);
        border-radius: 50px;
        padding: 5px 5px;
    }

    .action-nav button::after {
        right: 15px;
    }

    .featureSec-single [class*='col-'] {
        order: 2;
    }

    .featureSec-single [class*='col-']+[class*='col-'] {
        order: 1;
    }

    .featureSec-left {
        padding-top: 30px !important;
    }

    .navExpand {
        display: block;
        color: #FFF;
        font-size: 23px;
    }

    .projectTitle {
        display: block;
    }

    .projectTitle-right {
        justify-content: flex-start;
        padding-top: 15px;
    }

    .projectTitle-left h2 {
        font-size: 20px;
    }

    .projectTitle-left h2 span {
        font-size: 15px;
    }

    .tabLeft {
        min-height: 10px;
    }

    .manage-block {
        display: block;
    }

    .manage-block .btn {
        margin: 15px 0 0 0 !important;
    }

    .research-body-right {
        padding: 40px 0;
    }

    .editor-left {
        width: 100%;
        position: static;
        height: auto;
    }

    .editor-right {
        padding-left: 0;
    }
}

@media screen and (max-width: 767px) {
    .appFooter {
        position: static;
    }

    .loginLogo {
        width: 180px;
    }

    .login-container {
        padding-bottom: 50px;
    }

    .login-container-right-main h2 {
        font-size: 19px;
    }

    .login-container {
        padding-top: 90px;
    }

    .login-container-left h2 {
        font-size: 29px;
    }

    .login-container-left p {
        font-size: 14px;
    }

    .footer-single ul li a {
        font-size: 13px;
    }

    .footer-single p {
        font-size: 13px;
        padding: 3px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.02);
        border-bottom: 1px solid rgba(255, 255, 255, 0.02);
    }

    .loginHeader {
        padding-top: 15px;
    }

    .appFooter {
        padding: 5px 0;
    }

    .footer-single.reelLogo img {
        width: 100px;
    }

    .login-container-right-main {
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 5px;
    }

    .hero-banner-left h2,
    .hero-banner-left h3 {
        font-size: 26px;
    }

    .hero-banner-left p {
        padding-right: 0;
        font-size: 12px;
    }

    .hero-banner {
        padding-top: 0;
    }

    .bannerLink {
        font-size: 13px;
    }

    .videoProject-top h2 {
        font-size: 17px;
    }

    .proView {
        font-size: 15px;
    }

    .proView span {
        width: 25px;
        height: 25px;
        font-size: 16px;
        margin-left: 5px;
    }

    .videoProject-bottom {
        padding-top: 0;
    }

    .featureSec-single h2 {
        font-size: 18px;
    }

    .featureSec-single h6 {
        font-size: 12px;
    }

    .featureSec-single p {
        font-size: 13px;
    }

    .textL {
        font-size: 13px;
    }

    .lessonLink-right p {
        font-size: 13px;
    }

    .lessonLink-right h6 {
        font-size: 15px;
    }

    .lessonLink-right {
        padding-top: 30px;
    }

    .demoLink {
        font-size: 13px;
    }

    .hero-banner-left {
        padding-bottom: 15px;
    }

    .lessonLink {
        padding: 30px 0;
    }

    .projectTitle-right {
        display: block;
    }

    .projectTitle-right .demoLink {
        width: 100%;
        margin-top: 8px;
    }

    .project-input-box .input-group input {
        height: 38px;
    }

    .forproject .row.mt-5.mb-5 {
        margin-top: 15px !important;
    }

    .inteTop-main ul li {
        margin-left: 5px;
        margin-right: 5px;
    }

    h2.titleBdr {
        font-size: 22px;
    }

    .connection-single {
        display: block;
        height: auto;
        padding: 15px;
    }

    .connectBt {
        width: 100%;
        margin: 8px 0 0 0;
        font-size: 13px;
    }

    .single-left-img img {
        width: 40px;
    }

    .connection-single-left p {
        font-size: 12px;
        padding-left: 10px;
    }

    .inteTop-single img {
        max-height: 42px;
    }

    .inteTop-single {
        height: 120px;
    }

    .help-banner h2 {
        font-size: 21px;
    }

    .impTopic {
        padding: 50px 0;
    }

    .impTopic h2 {
        font-size: 20px;
    }

    .impTopic-single img {
        width: 120px;
    }

    .impTopic-single {
        height: 200px;
        margin-top: 15px;
    }

    .impTopic-single h3 {
        font-size: 15px;
    }

    .popularVideo h2 {
        font-size: 15px;
    }

    .popularVideo-single {
        margin-top: 15px;
    }

    .popularVideo {
        padding: 50px 0;
    }

    .helpDesk {
        padding: 40px 0;
    }

    .helpDesk-right {
        padding-top: 40px;
    }

    .helpDesk h6 {
        font-size: 14px;
    }

    .helpDesk p {
        font-size: 13px;
    }

    .helpSearch input[type="text"] {
        width: 100%;
    }

    .tabRight {
        padding: 30px;
    }

    .tabepaneIn-main {
        padding: 5px;
        border-radius: 10px;
    }

    .tabepaneIn-cont {
        padding: 10px;
        padding-top: 0;
    }

    .tabepaneIn-cont h3 {
        font-size: 14px;
    }

    .tabCont .nav-link {
        padding: 10px 15px !important;
        font-size: 12px;
    }

    .profileBlock {
        padding: 15px;
        padding-top: 20px;
    }

    .siteWrap-in {
        padding: 50px 0;
    }

    .proImage h5 {
        font-size: 17px;
    }

    .form-group-alt .form-control {
        font-size: 13px;
    }

    .manage-block .btn {
        font-size: 13px;
    }

    .manage-block {
        padding: 15px;
    }

    .footer-single ul li {
        margin-left: 20px;
    }

    .manage-block p {
        font-size: 13px;
    }

    .infoTitle {
        font-size: 16px;
    }

    .tabepaneIn-cont p {
        font-size: 12px;
    }

    .cont-research-head {
        display: block;
    }

    .cont-research-head h2 {
        font-size: 17px;
    }

    .research-head-right {
        display: block;
        padding-top: 10px;
    }

    .roundInp {
        width: 100%;
    }

    .research-block-top {
        display: block;
    }

    .research-block-top-right,
    .research-block-top-left {
        width: 100%;
    }

    .research-block.cont-scroll {
        height: 50vh;
    }

    .research-block {
        padding: 10px;
        border-radius: 10px;
    }

    .txtEdit-nav:first-child {
        width: 100%;
    }

    .txtEdit-nav:nth-child(2) {
        width: 100%;
        padding-left: 0;
        padding-top: 10px;
        margin-top: 10px;
        border-left: 0;
        border-top: 1px solid #707070;
    }

    .course-step ul li {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 10px;
    }

    .course-step ul li:first-child {
        margin-top: 0;
    }

    .create-course-mid {
        padding: 50px 0;
    }

    .create-course-top h2 {
        font-size: 20px;
    }

    .campaignBar {
        display: block;
    }

    .campaignBar .demoLink {
        width: 100%;
        margin: 5px 0 0 0;
    }

    .editorReel {
        padding-left: 10px;
        padding-right: 10px;
    }

    .canvasEditor {
        padding-left: 15px;
        padding-right: 15px;
    }

    .campaignNav .nav-item {
        width: 50%;
        padding: 2px;
    }

    .campaignNav .nav-link {
        width: 100%;
        height: 50px;
    }

    .campaignTop {
        position: absolute;
        width: 100%;
        left: 0;
        top: 70px;
    }

    .editorWrap {
        padding-top: 384px;
    }

    .reelmergeWrap {
        padding-top: 0px !important;
    }

    .videoControll {
        padding-left: 10px;
        padding-right: 10px;
    }

    .videoControll-txt span {
        font-size: 10px;
    }

    .videoControll-txt span+span {
        margin-left: 10px;
    }

    .videoControll-nav ul li {
        font-size: 15px;
    }

    .videoControll-right {
        font-size: 15px;
        transform: translateY(6px);
    }

    .videoControll-nav ul {
        transform: translateY(6px);
    }

    .inTabNav.nav .nav-link {
        font-size: 11px;
    }

    .motionlist-single {
        font-size: 11px;
    }

    .transition-txt {
        font-size: 11px;
    }
}