.font-active {
  border: 1px solid #ffa200;
  color: #fff;
}

.edit-input-lineheight {
  color: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #707070;
  background-color: #0C0C0C !important;
  height: auto;
  box-shadow: none;
  resize: none;
  width: 100%;
  height: 41px !important;
  letter-spacing: 1px;
  padding-left: 8px;
  font-size: 13px;
  outline: 1px !important;
}

.edit-input-lineheight:focus {
  border: 1px solid #ffa200;
}

.edit-input-font-size {
  color: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #707070;
  background-color: #0C0C0C !important;
  height: auto;
  box-shadow: none;
  resize: none;
  width: 100%;
  height: 37px !important;
  letter-spacing: 1px;
  padding-left: 8px;
  font-size: 13px;
  outline: 0px !important;
}

.edit-input-font-size:focus {
  border: 1px solid #ffa200;
}

.myImage-respo img {
  width: 100%;
}

.myImage-section {
  position: relative;
}

.crop-icon {
  position: absolute;
  top: 5px;
  left: 17px;
  width: auto !important;
  height: auto !important;
  z-index: 2;
  color: #ffa200;
}

.delete-media {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  filter: drop-shadow(2px 4px 6px black);
  width: unset;
  height: unset !important;
}

.remove-media {
  position: absolute;
  top: 6px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}

.remove-media:hover {
  color: #ffa200;
}

.delete-media svg {
  position: absolute;
  right: 11px;
  top: 11px;
  fill: #ffa200;
  cursor: pointer;
}

.delete-media i {
  color: #ffffff;
}

.slide-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffa200;
}

.slide-active i {
  color: #ffa200;
  transform: scale(1.5);
}

.active-audio .active-audio-wrapper i {
  color: #ffa200;
}

.active-audio .active-audio-wrapper {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000b3;
  z-index: 1;
  border-radius: 7px;
}

.active-audio-wrapper {
  display: none;
}

.card-icon-delete {
  position: absolute;
  top: 3px;
  cursor: pointer;
  color: #ffa200;
  left: 5px;
}

.loader-center {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #ffa200
}

.hover-loader-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffa200;
  font-size: 30px;
}

.loader-center-temp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-top-left {
  position: absolute !important;
  top: 0;
  left: 0;
  padding: 10px;
}

.logo-top-right {
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 10px;
}

.logo-bottom-right {
  position: absolute !important;
  bottom: 0;
  right: 0;
  padding: 10px;
}

.logo-bottom-left {
  position: absolute !important;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.logo-top-center {
  position: absolute !important;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
}

.logo-center-left {
  position: absolute !important;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 10px;
}

.logo-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.logo-center-right {
  position: absolute !important;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 10px;
}

.logo-bottom-center {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
}

.selected-tile {
  border: 2px solid #ffa200;
  border-radius: 10px;
}

.no-color::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 2px;
  height: 41px;
  background: crimson;
  transform: rotate(45deg);
  margin: auto;
}

.text-hover-effect:hover {
  border: 1px solid #ffa200;
}

.loader-center-temp1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffa200;
  height: 100%;
}

.set-Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: #ffa200;
  font-size: 30px;
}

.video-player-css {
  width: 100% !important;
  height: 100% !important;
}

.video-player-css video {
  object-fit: cover;
}


.asset-media {
  position: relative;
  padding-top: 56.25%;
}

.credit-content {
  height: 470px;
}

.credit-close {
  position: absolute;
  top: -4px !important;
  right: 5px !important;
}

.btn-custom-disable {
  color: rgb(128, 125, 125);
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.credit-modal {
  scrollbar-width: normal;
  scrollbar-color: #777 #555;
  overflow-x: hidden;
  overflow-y: auto !important;
  max-height: calc(100vh - 200px);
}

.credit-modal::-webkit-scrollbar {
  width: 10px;
}

.credit-modal::-webkit-scrollbar-thumb {
  background-color: #ffa200;
  border-radius: 10px;
}

.credit-modal::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

.credit-modal::-webkit-scrollbar-track {
  background-color: #5555;
}

.credit-modal::-webkit-scrollbar-track:hover {
  background-color: #555;
}

/* reel-merge-tiles */

.slide-sec-merge {
  background-color: #000000;
  padding: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
}

.slide-box {
  overflow-x: auto !important;
  overflow-y: hidden;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.slide-box::-webkit-scrollbar {
  height: 5px;

}

.slide-box::-webkit-scrollbar-thumb {
  background-color: #ffa200;
  width: 8px;
  border-radius: 10px;
}

.slide-box::-webkit-scrollbar-track {
  background-color: #5555;
}

.slide-box::-webkit-scrollbar-track:hover {
  background-color: #555;
}

/* merge-delete fontIcon on video tile */
.merge-delete {
  position: absolute;
  top: 2px;
  right: 7px;
  z-index: 1;
  cursor: pointer;
  color: #ffa200 !important;

}

.merge-btn {
  padding: 10px 20px;
  border-radius: 10px;
  color: #000;
  border: none;
  font-size: 14px;
  font-family: var(--poppins);
  font-weight: 500;
  margin-right: 10px;
  padding-left: 12px;
}

.merge-btn option {
  background-color: #fff;
  color: #000;
  border-radius: 10px !important;
}

select:focus-visible {
  outline: none;
}

.colmn-preview-wrapper-merge {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}


.voice-record-time span {
  font-size: 65px;
}

.record-img {
  width: 50px;
  height: 50px;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.master-box-content {
  /* width: 321px !important;
  height: 180px !important; */
  margin: 10px auto;
}
.master-box-content  div{
  width: 100% !important;
  height: 100% !important;
}

.master-box-content video {
  /* width: 321px !important;
  height: 180px !important; */
  border: 2px solid #F68802;
  border-radius: 10px;

}

.master-content {
  background-color: #000000;
  padding: 20px 30px;
  overflow-x: hidden !important;
  overflow-y: auto;
  max-height: 1055px !important;
}

.master-content::-webkit-scrollbar {
  height: 5px;
  width: 8px;

}

.master-content::-webkit-scrollbar-thumb {
  background-color: #ffa200;
  border-radius: 10px;
}

.master-content::-webkit-scrollbar-track {
  background-color: #5555;
}

.master-content::-webkit-scrollbar-track:hover {
  background-color: #555;
  display: none;
}

.recorder-space {
  display: none;
  color: #ffa200;
}

.audio-react-recorder canvas {
  display: none !important;
}

.credit-tab {
  width: 100%;
  height: 100%;
  background: #2b2b2b !important;
  color: #fff !important;
}

::marker {
  display: none !important;
  font-size: 0 !important;
}

.credit-video-card {
  border: 1px solid rgb(255, 255, 255);
  padding: 1px;
}

/* -> translate-text css  */
.translate-btn {
  display: inline-block;
  color: #FFF;
  font-weight: 600;
  padding: 5px 14px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  background: #ffa200;
}

/* -> upgrade-yotube css  */
.yotube-btn {
  display: inline-block;
  color: #FFF;
  font-weight: 600;
  padding: 8px 26px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  background: #ffa200;
}

.center-msg {
  display: flex;
  justify-content: center;
  align-items: center;
}


.intro-modal .modal-header button {
  color: #ffffff;

}

.sweet-alert-color h2 {
  color: black
}

.recorder-file {
  border: 1px solid #ffa200;
  padding: 0px 30px;
  border-radius: 10px;
  position: relative;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playicon {
  position: absolute;
  bottom: 7px;
  left: 7px;
  font-size: 20px;
  cursor: pointer;
}

.pauseicon {
  position: absolute;
  bottom: 7px;
  left: 7px;
  cursor: pointer;
  font-size: 20px;
}
/* .music-icon-design{
 
} */