.template-selectes-layer {
    border: 2px dashed #ffa200;
    padding: 2px;
}

.no-outline {
    outline: 0px !important;
}

.image-bg-border {
    border: 2px dashed #ffa200;
}

.logo-style {
    z-index: 2;
    max-width: 100px;
}

.watermark-op {
    opacity: .5;
}

.affiliate-wrapper {
    position: absolute;
    z-index: 2;
    padding: 0 12px;
}

.affiliate-text {
    padding: 0 10px;
}