#color-picker {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  right: 3px;
  width: 50px;
  height: 50px;
}

.form-group .sketch-picker {
  right: 12px !important;
}

.loader-sec {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader::after {
  width: 10px;
  height: 10px;
  background-color: #F68802;
  border-radius: 100%;
  position: absolute;
  content: "";
}

.loader {
  width: 80px;
  height: 80px;
  border: 2px #F68802 solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  text-align: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.resize-loader {
  font-size: 46px;
  color: #F68802;
}

.color-image-name {
  color: #F68802;
  overflow-x: hidden;
  text-align: center;
  padding: 0 5px;
}

.create-video-image {
  height: 100%;
  background: #fff;
}

.img-box2 {
  position: absolute;
  width: 13% !important;
  height: 65px;
  border-radius: 7px;

}

.counter {
  font-size: 12px;
  position: absolute;
  top: -9px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
}

#tooltip-top>.tooltip-inner {
  background-color: #707070;
  color: #fff;

}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #707070;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #707070;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #707070;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #707070;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.demo-message h6{
  font-size: 15px;
}
