@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');
/* @font-face {
  font-family: 'MyWebFont';
  src: url('myfont.woff') format('woff');
} */

:root {
  --poppins: 'Poppins', sans-serif;
  --Montserrat: 'Montserrat', sans-serif;
}

body {
  scroll-behavior: smooth;
}

body>iframe {
  pointer-events: none;
}
.App{
  height: 100vh;
  background: #2b2b2b;
}


@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

}

button {
  cursor: pointer !important;
}

body>iframe {
  pointer-events: none;
}




a:hover {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}

header .navbar {
  /*background-image: url('../images/Navbg.png');*/
  background-size: cover;
  background-position: center;
}

header .navbar .navbar-nav .active {
  border-bottom: 1px solid #FFA200;
}

.navmenuid {
  display: flex;
  align-items: center;
  justify-content: center;
}


.belldropdown button {
  background: linear-gradient(to right, #FFA200, #0482D8);
  padding: 7px 20px !important;
  border-radius: 30px;
  display: inline-block;
}


.belldropdown .dropdown-item {
  white-space: normal !important;
  background-color: #efeeee94;
  margin: 5px 0px;
  font-size: 14px;
}


.navmenuid .text h6 {
  font-size: 12px;
  color: #FFA200;
}

.navmenuid .text {
  color: #fff;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  margin-left: 10px;
}

header .dropdown-menu {
  position: absolute !important;
  left: -20px !important;
  top: 44px !important;
}

header .navbar ul li a {
  color: #fff !important;
  font-family: var(--poppins) !important;
  transition: .2s ease-in-out;
  padding: 0px 0px;
}

header .navbar ul li:hover a {
  color: #FFA200 !important;
  transition: .2s ease-in-out;
}

header .navbar ul li {
  margin-right: 30px;
}


header button#dropdownMenuButton span {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;

  cursor: pointer;
  transition: .2s ease-in-out;
  overflow: hidden;

}

.dropdown-menu.show {
  display: block;
  transition: .2s ease-in-out;
}

header .dropdown-toggle::after {
  display: none !important;
}

header .dropdown button {
  padding: 0;
  border: none;
  background-color: transparent;
}

header .dropdown button:focus {
  box-shadow: none;
  outline: none !important;
}

header .dropdown-item {
  font-size: 15px;
  color: #000;
}

header .dropdown-item:hover {
  background: #FFA200;
}

.bashboard-banner .banner-items-list {
  background: linear-gradient(0.25turn, #000000, #000000);
  border-radius: 15px;
  padding: 20px;
}

.bashboard-banner .banner-items p {
  color: #fff !important;
  font-size: 18px;
  font-size: 18px;
}

.bashboard-banner .banner-items a {
  margin-top: 15px;
}

.bg2 {
  background: linear-gradient(0.25turn, #000000, #000000) !important;
}



.banner-content {
  padding: 136px 0px;
}

.banner-items {
  padding: 206px 0px;
}


.bashboard-banner {
  position: relative;
}

/* .bashboard-banner::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #15191E;
  top: 0;
  left: 0;
  opacity: 0.8;
} */

.bashboard-banner {
  /*background-image: url('../images/Mask\ Group\ 3.png');*/
  background-size: cover;
  background-position: top center;
  width: 100%;

  background-repeat: no-repeat;

}

.banner-content .watch-demo-btn button {
  background-image: linear-gradient(to right, #FFA200, #FFA200, #FFA200);
  padding: 15px 58px;
  border-radius: 30px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--poppins);
  cursor: pointer;
  overflow: hidden;
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}



.banner-content .watch-demo-btn button span {
  margin-right: 5px;
  color: #fff;

}


.banner-content h4 {
  font-size: 75px;
  font-family: sans-serif;
  font-weight: 800;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

.banner-content h1 {
  font-size: 67px;
  font-weight: 800;
  font-family: var(--Montserrat);
}

.banner-content h1 span {
  color: #fff;
}

.banner-content p {
  width: 544px;
  font-family: var(--poppins);
  font-size: 18px;
  font-weight: 300;
}

.dashborad-sec-3 {
  position: relative;
  overflow: hidden;
  padding: 80px 0px;
  background-color: #15191E;
}

.banner-video-project {
  background-color: #15191E;
  padding: 40px 0px;
}

.banner-video-project .video-project h5 {
  font-size: 40px;
  font-family: var(--poppins);
}

.banner-video-project .video-project h5 span {
  color: #FFA200;
}


.banner-video-project .video-project a span {
  color: #FFA200;
  margin-left: 20px;
}

.video-card {
  background-image: linear-gradient(to bottom, #0D696D, #0D696D);
  border-radius: 10px;
  padding-top: 1px;
}


.project-create-btn button:hover {
  color: #F68802 !important;
}


.ClipsReel-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .8s ease-in-out;
  background-color: #000000a3;
  border-radius: 10px;
  clip-path: circle(30.3% at 100% 0);
}

.ClipsReel-overlay ul {
  height: auto;
}

.ClipsReel-overlay ul li a span {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vidoe-play-icon span i {
  color: #fff !important;
}


.ClipsReel-overlay ul li:hover i {
  color: #FFA200 !important;
}

.ClipsReel-overlay ul li:hover a {
  color: #FFA200;
}

.ClipsReel-overlay ul li a {
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ClipsReel-overlay ul li a i {
  font-size: 18px;
  color: #000;
}


.video-img:hover .ClipsReel-overlay {
  opacity: 1;
  transition: .8s ease-in-out;
  clip-path: circle(141.4% at 100% 0);
}

.video-card .video-img {
  border-radius: 5px;
  position: relative;
  margin: 10px;
}

.video-card .video-title {
  padding: 10px;
  padding-top: 0px;
}

.video-card .video-title .vidoe-play-icon span {
  cursor: pointer;

}

.video-name {
  width: 100%;

}

.video-name p {
  color: #fff;
  font-family: var(--poppins);
  font-size: 14px;

  text-align: center;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* .vidoe-play-icon span img {
  width: 45px;
} */

.banner-video-project .video-project a {
  color: #fff;
  font-size: 25px;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover {
  color: #FFA200;
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover span {
  color: #FFA200;
}

.modal-body {
  background: #000;
  overflow: hidden;
  border-radius: 20px;
  padding: 25px;
}

.modal-header {
  background: #000;
  border-bottom: none;
  border-radius: 11px;
  display: flex;
  justify-content: center;
}

.crop-header {
  border-bottom: 1px solid #fff !important;
  border-radius: 0 !important;
  justify-content: flex-start !important;
}

.crop-header {
  border-bottom: 1px solid #fff !important;
  border-radius: 0 !important;
  display: flex;
  justify-content: flex-start !important;
}

.modal h5 {
  color: #fff;
}

.cate-submit-btn {
  background: linear-gradient(#FFA200, #FF8000);
  text-align: center;
  border-radius: 20px;
  color: #fff;
}

.cate-submit-btn button {
  color: inherit;
}

.cate-submit-btn:hover {
  color: #FFA200;
  box-shadow: 400px 0 0 #fff inset;
  transition: .3s ease-in-out;
}

.cate-active {
  background-color: #41acd1 !important;
}

.cateSingle:hover {
  background-color: #41acd1 !important;
}

.cate-submit-btn:hover button {
  color: #FFA200;
}

.dashborad-sec-3-content h4 {
  font-size: 30px;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}


.dashborad-sec-3-content h4:hover {
  color: #FFA200;
  transition: .2s ease-in-out;
}

.dashborad-sec-3-content h6 {
  font-size: 18px;
  font-family: var(--poppins);
  color: #FFA200;
  text-transform: uppercase;

}

.dashborad-sec-3-content p {
  font-size: 18px;
  font-family: var(--poppins);
  font-weight: 200;
}

img {
  max-width: 100%;
}

/* .imgsizeleft {
  margin-left: 50px;
}

.imgsizeright {
  margin-left: -320px;
} */


.logoimg {
  /*background-image: url('../images/BG.png');*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}



.login-box {
  padding: 0px 0px 30px;
  min-height: calc(100vh - 125px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-content {
  position: relative;
}

.login-content h1 {
  font-size: 63px;
  font-family: var(--Montserrat);
  font-weight: 800;
  margin-left: 46px;
  /* text-align: center; */
}

.login-content .login-head-text::before {
  position: absolute;
  left: 30px;
  content: "";
  width: 6px;
  border-radius: 30px;
  height: 35%;
  background-color: #FFA200;

}

.login-content .welcome-text {
  color: #FFA200;
  position: absolute;
  display: inline-block;
  font-size: 35px;
  font-family: var(--Montserrat);
  font-weight: 800;
  margin-left: 43px;
  margin-top: -10px;
}

.reset-text {
  margin-left: 25px !important;
}

.reset-para {
  margin-left: 15px !important;
}


.login-content p {
  width: 90%;
  font-size: 18px;
  font-family: var(--poppins);
  font-weight: 300;
  margin: auto;
  color: #fff;
}

.login-content h5 {
  font-size: 29px;
  font-family: var(--poppins);
  margin-left: 29px;
}



.login-form {
  padding: 50px;
  background-color: #07589F;
  border-radius: 25px;
  border: 1px solid #07589F !important;
}

.login-form h4 span {
  color: #FFA200;
}

.login-form h4 {
  font-family: var(--Montserrat);
  font-size: 26px;

}

.login-form button {
  background: #FFA200;
  border: none;
  border-radius: 30px;
  padding: 10px;
  color: #fff;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: var(--poppins);
  cursor: pointer;
}

.login-form button:focus {
  color: #FFA200 !important;
  background-color: #fff !important;
}

.login-form button:focus {
  background-image: none !important;
}

.login-form .forgot a {
  color: #C3C3C3 !important;
}

.login-form .forgot span:hover {
  color: #FFA200;
  transition: .2s ease-in-out;
}

.login-form .form-group input {
  background-color: #fff !important;
  border-radius: 10px !important;

}

.login-form .form-group2 {
  background-color: #FFA200;
  padding-left: 7px;
  border-radius: 10px;
}



.mail {
  display: inline-block;
  position: absolute;
  color: #FFA200;
  font-size: 32px;
  z-index: 1;
  margin-top: -4px;
  margin-left: 12px;

}

.line {
  border-right: 1px solid #707070;
  position: absolute;
  margin-top: 12px;
  margin-left: 55px;
  height: 28px;
  z-index: 2;
}

.mail-2 {
  position: absolute;
  color: #FFA200;
  font-size: 28px;
  margin-left: 12px;
  z-index: 1;

}

.login-form .form-group input:focus {
  box-shadow: none !important;
}

.login-form .form-group .form-control {
  color: #000 !important;
  position: relative;
}


.login-form a span {
  color: #FFA200;
  margin-left: 5px;
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li a {
  color: #fff;
  font-size: 14px;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li a:hover {
  color: #ffa200;
  transition: .2s ease-in-out;
}

.footer-copyright p a {
  color: #fff;
  transition: .2s ease-in-out;
}

.footer-copyright p a:hover {
  color: #FFA200;
}

.footer .footer-menu ul li+li {
  margin-left: 40px;
}

.footer .footer-copyright p {
  font-size: 14px;
  font-family: var(--poppins);
}

.footer .footer-copyright p a {
  transition: .2s ease-in-out;
  color: #00cdff;
}
.footer .footer-copyright p a:hover{
  color: #ffa200;
}

.footer {
  background-color: #0000007a;
  padding: 16px 0px;
}



.footerbg {
  background-color: #000000;
  padding: 13px 0px;
}

.vineasx-logo img {
  width: 170px;
  object-fit: cover !important;
}




.banner-items .banner-items-list .col-md-3 img {
  transform: scale(1);
  transition: .4s ease-in-out;
}

/* .banner-items .banner-items-list{
    transform: scale(1);
    transition: .4s ease-in-out;
}

 .banner-items .banner-items-list:hover{
    transform: scale(1.05);
    transition: .4s ease-in-out;
}  */

.banner-items .banner-items-list:hover .col-md-3 img {
  transform: scale(0.9);
  transition: .4s ease-in-out;
}



.project-banner {
  padding: 20px 0px;
  background-color: #15191E;
}


.project-header-name .project-conut h6 {
  color: #A2A2A2;
  font-size: 25px;
  font-family: var(--poppins);
  margin-left: 30px !important;
  position: relative;
  font-weight: 200;
}

.project-header-name .project-conut h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: #A2A2A2;
  top: 3px;
  left: -15px;
}



.project-header-name .project-item {
  font-size: 25px;
  font-family: var(--poppins);
  font-weight: 600;
}



.video-img img {
  width: 100%;
  height: 192px;
}


.project-button .project-create-btn button {
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  background: #F68802;
  border: none;
  font-size: 16px;
  font-family: var(--poppins);
  font-weight: 600;
  cursor: pointer;
}

.project-button .project-create-btn button:focus {
  background-color: #fff !important;
  background-image: none !important;
  color: #F68802 !important
}

.project-input-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.project-input-box .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.project-input-box .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.project-input-box .input-group input:focus {
  box-shadow: none !important;
}

.project-input-box ::placeholder {
  color: #7A7A7A !important;
  font-size: 14px;
  font-family: var(--poppins);
}

.project-input-box {
  margin-right: 20px;
}

.project-banner .bottom-line {
  border-bottom: 1px solid #A2A2A2;
  padding-bottom: 20px;
  display: block;
  width: 98%;
  margin: auto;
}

.project-banner {
  min-height: 100vh;
  padding-bottom: 40px;
  padding-top: 50px;
}



.login-form .form-group label {
  font-size: 20px;
  font-family: var(--poppins);
  color: #C3C3C3;
  margin-left: 10px;
}

.login-form .form-group input {
  height: 50px;
  padding-left: 70px;
}

.login-form ::placeholder {
  font-size: 16px;
  color: #707070;
  font-family: var(--poppins);


}

.btn-change6 {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;

}






.login-form button:hover {
  color: #FFA200 !important;
}


.btn-change6:hover {
  box-shadow: 1000px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.medium-btn {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}


.medium-btn:hover {
  box-shadow: 300px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.btn-change7 {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}

.btn-change7:hover {
  box-shadow: 752px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}

.toggleupdate {
  background: linear-gradient(to left, #FFA200, #FFA200);
  border: none;
  padding: 7px 30px;
  border-radius: 30px;
  color: #fff;
  height: 45px;
}

.toggleupdate:hover {
  color: #FFA200;
}

.cancl-btn a {
  color: #fff;
}

.btn-cancl {
  position: relative;
  z-index: 9999;
  display: inline-block;
}

.cancl-btn {
  background: #FFA200;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: #fff;
  overflow: hidden;
  z-index: 2;
  position: relative;
}

.cancl-btn:hover {
  color: #FFA200 !important;
}


.cancl-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  /* background: linear-gradient(to left, #FFA200, #FFA200); */
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 30px;

}

.cancl-btn:hover::before {
  width: 100%;
  background-color: #fff;
  transition: .3s ease-in-out;
  color: #fff !important;
}


.fixed-me {
  /*background-image: url('../images/Navbg.png');*/
  background-size: cover;
  background-position: center;

  margin-bottom: 5px;
  position: fixed;
  top: 0;
  z-index: 7;
  width: 100%;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}

.bashboard-banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #15191e;
  top: 0;
  left: 0;
  opacity: .8;
}

.bashboard-banner #bg-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  bottom: 0;
  z-index: -1;
  overflow: hidden;

}
.bashboard-banner #bg-video video{
  height: 100%;
  object-fit: cover;
}

#bg-video iframe {
  position: absolute;
  min-height: 100vh;
  min-width: 177.77vh;
  width: 100vw;
  height: 56.25vw;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: linear-gradient(rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7)50%);

}




.watch-demo-btn button {
  position: relative;
  z-index: 2;
}

.watch-demo-btn button::before {
  content: '';
  width: 0%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 30px;
  color: #FFA200;
  transition: .3s ease-in-out;
  z-index: -1;
}

.watch-demo-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  border-radius: 30px;
}

.watch-demo-btn button:hover {
  color: #FFA200 !important;
  transition: .4s ease-in-out;
}

.select-box .form-group {
  width: 260px;
  border: 1px solid #FFA200;
  border-radius: 5px;
  margin-right: 20px !important;
}

.select-box .form-group select {
  border: none !important;
}

.select-box .form-group select:focus {
  box-shadow: none !important;
}

.select-box .form-group select {
  background-color: #101010;
}

.search-select .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.search-select .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.search-select .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.search-select .input-group input:focus {
  box-shadow: none !important;
}

.search-select ::placeholder {
  color: #8b7676 !important;
  font-size: 14px;
  font-family: var(--poppins);
}



.premium-header {
  background-color: #303030;
  padding: 15px 0px;
  margin-top: 65px;
}

.select-box select {
  color: #fff !important;
}


.template-name h4 {
  font-size: 22px;
  font-family: var(--poppins);
  font-weight: 400;
}



.template-number h6 {
  color: #A2A2A2;
  font-size: 22px;
  font-family: var(--poppins);
  margin-left: 30px;
  position: relative;
  font-weight: 200;
}


.template-number h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 18px;
  background-color: #A2A2A2;
  top: 4px;
  left: -15px;
}


.template-body {
  padding: 60px 0px;
  background-color: #171717;
  min-height: 100vh;
}

.template-names h6 {
  color: #DEDFDD;
}


/* .template-body .row {
    overflow-y: auto;
    height: 60vh;
} */




.template-body ::-webkit-scrollbar {
  width: 5px;
}

.template-body ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.template-body ::-webkit-scrollbar-thumb {
  background: #F68802;
  border-radius: 10px;
  height: 10vh !important;
}


.customModal .modal-dialog .modal-body {
  height: 60vh;
}

.customModal .modal-dialog .modal-footer button {
  background: #F68802;
  padding: 7px 25px;
  border: none;
  color: #fff;
  border-radius: 5px;
}

.customModal .modal-dialog .modal-footer button:hover {
  color: #F68802;
}

.customModal .modal-dialog .modal-footer button:focus {
  background: #fff;
  color: #F68802;
}

.customModal .modal-dialog .modal-footer {
  border: none;
}

.customModal .modal-scrollbar {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.modal-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.modal-scrollbar::-webkit-scrollbar-thumb {
  background: #F68802 !important;
  border-radius: 10px;
  height: 10vh !important;
}



/* .template-body ::-webkit-scrollbar-thumb:hover {
  background: #b30000;
} */



.modal-header .close {
  padding: 0px !important;
  margin: 0px !important;
}

.img-close {
  position: absolute;
  top: 14px !important;
  right: 6px !important;
  opacity: 1 !important;
}

.embed-responsive {
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  position: relative;
  aspect-ratio: 16/9;
}

.embed-responsive iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.modal-header .modal-title span {
  color: #F68802;
}

.modal-dialog {
  max-width: 1000px !important;
  height: 100%;
}

.close {
  position: absolute;
  top: 0px;
  right: 7PX;
  opacity: 1 !important;
  z-index: 1;
}

.modal-content {
  background: #000 !important;
  border: none !important;
  /* margin-top: 51px; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.fb-content {
  position: relative !important;
}

.slide-content {
  position: static !important;
}


.modal-title button span {
  color: #fff !important;
}

.facebook-item-box {
  border: 1px solid #707070;
  border-radius: 10px;
  background-color: #1C1C1C;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box:hover {
  border: 1px solid #F68802;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box h6 {
  color: #6A6A6A;
}

.facebook-item-box h5 {
  font-size: 14px;
}

.vidoe-play-icon {
  margin-top: 6px;
}



/* editor css start */


.editor-sec {
  background: #000000;
  min-height: 95vh;

  overflow: hidden;
}

.save-render-sec {
  background-color: #0c0c0c9e;
  padding: 10px;
}

/* .render-projectbtn {
    margin-right: 85px;
} */
.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}

.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}


.editor-part ::-moz-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part ::-moz-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part ::-moz-scrollbar-thumb {
  background: #F68802 !important;
  border-radius: 10px !important;
  height: 10px !important;
}








.editor-part::-webkit-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part::-webkit-scrollbar-thumb {
  background: #F68802 !important;
  border-radius: 10px !important;
  height: 10px !important;
}





.save-render-sec .add-campaign input {
  border-radius: 30px;
  border: none;
  background: #161616;
  width: 290px;
  height: 42px;
}

.save-render-sec ::placeholder {
  color: #A5A5A5;
}

.save-render-sec .add-campaign .form-control {
  color: #A5A5A5;
}

.save-render-sec .save-projectbtn button {
  background: #F68802;
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.save-render-sec .render-projectbtn button {
  background: linear-gradient(to right, #A72CE3, #CB3B71);
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.editor-tab-menu .nav-item .nav-link h6 {
  color: #fff;
  margin-top: 5px;
  font-size: 12px;
  font-family: var(--poppins);
  margin-bottom: 0px;
}

.editor-tab-menu .nav-item {
  padding: 0px 10px;
}

.editor-tab-menu .nav-item .nav-link.active {
  background: #414141;
  border-radius: 10px;
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link.active h6 {
  color: #F68802;
}

.editor-tab-menu .nav-item .nav-link {
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link img {
  width: 17px;
}

.editor-tab-menu {
  padding: 10px;
}

.editor-preview {
  padding: 40px 0px;
  background: #333333;
  position: relative;
  /* width: 110%; */
}


.story-part-sec .story-para p {
  font-size: 12px;
  font-family: var(--poppins);
  color: #fff;
}

.story-part-sec .story-para {
  background-color: #242424;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid #707070;
}

.editor-preview-box {
  width: 656px;
  height: 369px;
  overflow: hidden;
  margin: auto;
}

.para-active {
  background-color: #F68802 !important;
}

.slide-box ul li {
  width: 150px;
  height: 70px;
  border: 2px solid #434343;
  background-color: #101010;
  margin: 0px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #9A9A9A;
  border-radius: 12px;
  font-size: 14px;
}

/* merge-add-css */
.slide-box ul li .box-ar-wrapper img,
.slide-box ul li .box-ar-wrapper video {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
}

.slide-box ul li .box-ar-wrapper {

  background-color: #101010;
  border-radius: 10px;
  overflow: hidden;
}

.slide-box ul li:hover {
  border-color: #FFA200;
  transition: .3s ease-in-out;
}

/* merge-add-css-end */


.slide-sec {
  background-color: #0c0c0c;
  padding: 15px;
}

.slide-box .plus-icon i {
  color: #565656;
}

.start-end-time input {
  width: 100px;
  background: transparent;
  border: none;
  color: #fff !important;
}

.start-end-time input:focus-visible {
  outline: none !important;
}

.start-end-time input.form-control {
  color: #fff;
}

.start-end-time ::placeholder {
  color: #fff;
}


.controls-box {
  background: #000;
  padding: 20px;
  width: 657px;
  margin: auto;
  position: relative;
  z-index: 999;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 3px 5px -1px #000;
}



.controls-box .start-end-time ul li>input:focus {
  box-shadow: none !important;
}

.save-projectbtn button:hover {
  color: #F68802;
}

.save-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #F68802 !important;
}

.add-campaign input:focus {
  box-shadow: none;
}

.render-projectbtn button:hover {
  color: #F68802;
}

.render-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #F68802 !important;
}

.style-upload-part {
  background: #303030;
  /* padding: 10px; */
  border-radius: 10px;
}

.style-upload-box {
  background-color: #171717;
  height: 77px;
  border: 1px dashed #707070;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style-upload-box .custom-file-input {
  position: absolute;
  top: 0;
  opacity: 0;
  background: red;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.style-upload-box h6 {
  font-size: 12px;
  margin-bottom: 0px;
  color: #8F8F8F;
}

.style-upload-box .custom-file {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.position-text-box {
  background: #303030;
  border-radius: 10px;
}

.position-text-box .text-position-dropdown label {
  font-size: 14px;
  margin-left: 14px;
}

.position-text-box .text-position-dropdown .box:hover {
  background-color: #F68802;
  transition: all .2s;
  border: 2px solid #F68802;
}

.position-text-box .text-position-dropdown .box-wrapper .box.box-active {
  background-color: #F68802 !important;
  border: 2px solid #F68802 !important;

}

.position-text-box .text-position-dropdown .box {
  margin: 5px;
  border: 2px solid #707070;
  border-radius: 0;
  background-color: #636363;
  cursor: pointer;
  padding: 13px 17px;
  width: 20px;
  height: 20px;
  color: #fff;
  transition: all .2s;
}

.position-text-box .text-position-dropdown .box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -4px;
}

.remove-logo-switch-btn .form-check label {
  font-size: 14px;
  color: #8F8F8F;
}

.remove-logo-switch-btn .form-check .form-check-input {
  margin-top: 2px;
  margin-left: 10px;
  font-size: 24px;
}

.style-remove-cricle {
  width: 50px;
  height: 50px;
  background-color: #000;
  border-radius: 50%;
  border: 2px solid #686868;
}

.dimmer-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.dimmer-wrapper .dimmer-light h6 {
  font-size: 14px;
}

.dimmer-switch-btn .remove-logo-switch-btn .form-check-input {
  margin-top: 2px;
  font-size: 24px;
}

.swap-template-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.swap-template-wrapper .swap-template-box {
  background-color: #101010;
  height: 100px;
}

.swap-template-wrapper h6 {
  font-size: 14px;
}

.editor-tab-menu {
  /* width: 110%; */
  background: #232323;
}



/* media tab css start */




.media-menu-box .nav-item .nav-link {
  color: #fff;
  background-color: #3E3E3E;
  padding: 6px 20px;

  font-size: 13px;
  padding: 9px;
  font-family: var(--poppins);
  text-align: center;

}

.media-menu-box .nav-item .nav-link.active {
  background-color: #fff;
  color: #F68802;
  font-size: 13px;
  padding: 9px;
  font-family: var(--poppins);
  text-align: center;
}

.media-search-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #979797;
  cursor: pointer;
}

.media-search-box .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: none;

}

.media-search-box .input-group-prepend {
  margin-left: 0px;
}

.media-search-box input:focus {
  box-shadow: none;
}

.search-laod {
  background-color: #101010;
  height: 100px;
}

.outro-video {
  border: 1px solid #fff;
  border-radius: 5px;
  height: 100%;
}

.intro-video {
  border: 1px solid #fff;
  border-radius: 5px;
  height: 100%;
}

.media-upload-box button {
  background: linear-gradient(to right, #B376FF, #4AC6FF);
  border: none;
  color: #fff;
  border-radius: 30px;
}

.media-upload-box {
  position: relative;
}

.media-upload-box .custom-file {
  position: absolute;
  top: 0px;
}

.media-upload-box .custom-file .custom-file-input {
  opacity: 0;
  background-color: red;
  border-radius: 30px;
  font-size: 2px;
  position: absolute;
  top: 0px;
  cursor: pointer;
}

/* media start css */


.credites-wrapper textarea {
  background-color: #0C0C0C;
  border: 1px solid #707070;
}

.credites-wrapper textarea:focus {
  background-color: #0C0C0C;
  box-shadow: none;
}

.credites-wrapper textarea.form-control {
  color: #787878;
}


.credites-wrapper label {
  font-family: var(--poppins);
}

.credites-upload-box .outer-images {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
}

.credites-uplaod {
  position: relative;
  height: 100px;
  background-color: #171717;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #707070
}

.credites-uplaod h6 {
  font-size: 14px;
  color: #8F8F8F;
}

.credites-uplaod .custom-file {
  position: absolute;
  top: 0;
}


.credites-uplaod .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  height: 261%;
  font-size: 1px;
  cursor: pointer;
}

.credites-upload-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.remove-outer-logo .form-check-input {
  margin-left: -5px;
  font-size: 24px;
  margin-top: 0px;
}

.outro-template-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.outro-template-box h6 {
  font-family: var(--poppins);
}

.outer-images-box {
  background-color: #000000;
  height: 100px;
}


/* audio editor css start */



.audio-box-sec .audio-box-menu .nav-item .nav-link.active {
  background-color: #fff;
  color: #10CA9C;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  font-family: var(--poppins);
}

.audio-box-sec .audio-box-menu .nav-item .nav-link {
  background-color: #3E3E3E;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-family: var(--poppins);
  padding: 10px;
  text-align: center;
}

.horigental-line {
  border: 1px solid #BEBEBE;
  width: 100%;
  display: block;
}

.audio-music-box .card {
  background: linear-gradient(to bottom, #00EDBC, #009DB0);
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.audio-music-box .card:hover {
  transform: scale(1.05);
  transition: .4s ease-in-out;
}

.audio-music-box .card .card-footer {
  background-color: #54D1CE;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}


.audio-music-box .card .card-title {
  padding: 10px;
  font-size: 14px;
}

.audio-music-box .card-body {
  padding: 0px;
}

.card-music-name h6 {
  font-size: 14px;
}

.audio-slide-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-voice-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-slide-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-language-box .form-group .form-select {
  padding-right: 0px;
}

.audio-voice-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-voice-box .form-group .form-select {
  padding-right: 0px;
}

.audio-add-text-box textarea {
  background: #0C0C0C;
  border-color: #707070;

}

.audio-add-text-box input {
  background: #0C0C0C !important;
  border-color: #707070 !important;

}

.audio-add-text-box textarea:focus {
  background: #0C0C0C;
  box-shadow: none;
}

.audio-add-text-box textarea.form-control {
  color: #787878;
}

.tts-box-wrapper {
  background: #303030;
  border-radius: 10px;
  padding: 10px;
}

.audio-preview-btn button {
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}


.audio-preview-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}

.audio-library-boxs .audio-upload-btn button {
  border-radius: 30px;
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  height: 45px;
  color: #fff;
}

.audio-upload-btn {
  position: relative;
}






.audio-library-boxs .audio-upload-btn .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  border-radius: 30px;
  font-size: 1px;
  height: 45px;
  cursor: pointer;
}

.audio-library-boxs .audio-upload-btn .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  top: 0;
}


.upload-box-card .card {
  background: linear-gradient(to bottom, #B94EFF, #DB438F);
  border: none;
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.upload-box-card .card:hover {
  transform: scale(1.05);
  transition: .4s ease-in-out;
}

.upload-box-card .card-footer {
  background: #B240E6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.upload-box-card .card-title {
  padding: 10px;
  font-size: 14px;
}

.upload-box-card .card-body {
  padding: 0px;
}

.card-play-icon {
  cursor: pointer;
}


.remove-logo-switch-btn .form-check-input:checked {
  background: #C23636;
  box-shadow: none;
  border-color: #C23636;
}

.remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}



.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked {
  background: #00BF85;
  box-shadow: none;
  border-color: #00BF85;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}


header .editormenu {
  left: -182px !important;
}

.slide-box {
  overflow-x: auto;
  overflow-y: hidden;
}

.text-font-size {
  width: 120px;
}

.text-box-sec .text-box-wrapper select {
  background-color: #0C0C0C;
  color: #fff;
}

.text-box-sec .text-box-wrapper .text-font-style select {
  border-color: #00F5A0;
}


.font-style-boxs .btn-group button {
  background-color: #0C0C0C;
  margin: 0px 10px;
  border-radius: 5px !important;
}



.profile-sec {
  background-color: #171717;
  padding: 120px 0px 60px;
  min-height: 100vh;
}

.user-img {
  position: relative;
  width: 100px;
  height: 100px;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow: hidden;
}

.user-img .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 65px);
  margin-bottom: 0;
  top: 0;
  left: 0;
  background: red;
  font-size: 1px;
  border-radius: 50%;
  opacity: 0;
}


.user-img .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  background: orange;
  border-radius: 100%;
  cursor: pointer;
}

.user-details h5 {
  font-size: 24px;
  font-family: var(--poppins);
  color: #fff;
}

.user-details button {
  padding: 10px 30px;
  border: none;
  background: #F68802;
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--poppins);
}

.user-details .user-img span {
  color: #14BB9B;
}


.form-box .form-group input {
  background-color: transparent !important;
  border: none;
  padding: 0px;
}

.form-box .form-group input:focus {
  box-shadow: none !important;
}

.form-box .form-group {
  background-color: #1E1E1E;
  color: #fff;
  padding: 5px 20px;
  border-radius: 10px;
  border: 1px solid #707070;
  position: relative;
}

.formactive {
  border: 1px solid #00F8B8 !important;
}

.form-box .form-group .form-control {
  color: #fff !important;
  font-weight: 200;
  font-size: 14px;
  font-family: var(--poppins);
}

.form-box .form-group ::placeholder {
  color: #6c757d !important;
}


.personal-details-box h6 {
  font-size: 20px;
  font-family: var(--poppins);
  color: #fff;
}

.form-box button {
  background: #F68802;
  border-radius: 10px;
  border: none;
  height: 45px;
  color: #fff;
}

.form-box button:hover {
  color: #F68802 !important
}

.form-box button:focus {
  background: #fff;
  color: #F68802;
}

.form-box {
  background-color: #000;
  padding: 50px;
  border-radius: 30px;
}

.form-space {
  padding: 50px 50px 0 50px !important;

}


.gdpr-sec {
  padding: 80px 0px 80px;
  min-height: 100vh;
  background: #171717;
}

.gdpr-update-email {
  padding: 30px;
  background: #000000;
  border-radius: 10px;
}

.gdpr-sec .gdpr-update-email .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.gdpr-update-email .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}




.gdpr-update-email .form-group input:focus {
  box-shadow: none !important;
}

.gdpr-update-email .form-group .form-control {
  color: #fff;
  font-weight: 200;
  font-size: 14px;
  background: none;
}

.gdpr-update-email .form-group ::placeholder {
  color: #6c757d;
}

.gdpr-update-btn button {
  background: #F68802;
  border: none;
  color: #fff;
  border-radius: 30px;
  padding: 7px 30px;
}

.gdpr-update-btn button:hover {
  color: #F68802;
}

.gdpr-update-btn button:focus {
  background: #fff;
  color: #F68802;
}


.manage-data-box {
  background: #000000;
  padding: 40px 20px;
  border-radius: 10px;
}

.manage-data-box p {
  font-size: var(--poppins);
}

.manage-data-box p span {
  color: #f27277;
}

.manage-update-btn button {
  border: none;
  padding: 7px 20px;
  border-radius: 30px;
  color: #fff;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.manage-update-btn button::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 30px;
  z-index: -1;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover {
  color: #F68802;
  position: relative;
  z-index: 999;
  overflow: hidden;
}



.add-account-sec {
  padding: 60px 0px;
  background: #171717;
  min-height: 100vh;
}

.add-account-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

.add-account-box .form-group input {
  background: transparent;
  border: none;
  padding: 0px;
}

.text-muted {
  color: #ffffffbd !important;
}


.add-account-box .form-group input:focus {
  box-shadow: none;
}

.add-account-box .form-group ::placeholder {
  color: #6c757d;
  font-weight: 200;
}

.add-account-box .form-group .form-control {
  color: #fff;
  font-weight: 200;
}

.add-account-box .form-group select {
  background: transparent;
  border: none !important;
  padding: 0px;
  text-align: left;
  height: 24px !important;
  color: #6c757d;
}

.add-account-box .form-group select:focus {
  box-shadow: none;
}

.add-account-box .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}

.add-account-box .form-group:focus-within {
  border-color: #F68802 !important;
}

.add-account-save-btn button {
  background: #F68802;
  border: none;
  padding: 7px 30px;
  color: #fff;
  font-family: var(--poppins);
  border-radius: 30px;
}


.add-account-save-btn button:hover {
  color: #F68802;
}

.add-account-save-btn button:focus {
  background: #fff;
  color: #F68802;
}

.team-member-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

.text-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;

}


.help-support-banner {
  background: url(../src/images/help-bg.png);
  background-size: cover;
  margin-top: 65px;
  overflow: hidden;
  min-height: 60vh;
}

.support-img img {
  width: 460px;
}


.customer-sec {
  padding: 60px 0px;
  background-color: #002D3D;
}

.customer-content h6 {
  color: #F68802;
}

.customer-content h5 {
  font-size: 28px;
  font-family: var(--poppins);
}

.customer-content a {
  padding: 10px 30px;
  background-color: #F68802;
  color: #fff;
  border-radius: 30px;
  margin-top: 10px;
  display: inline-block;
  font-weight: 600;
}

.customer-content a:hover {
  color: #F68802;
  font-weight: 600;
}

.gdpr-update-email .form-group:focus-within {
  border-color: #F68802 !important;
}

.form-group:focus-within {
  border-color: #F68802 !important;
}

.secarchicone-part #searchimg {
  position: relative;
  left: 40%;
}



.help-banner-content .input-group .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-left: 0px;
  background-color: #fff;
  border: none;
  color: #7A7A7A;
  font-size: 13px;
  padding-right: 0px;
  cursor: pointer;
}

.help-banner-content .input-group .input-group-prepend {
  margin-right: 1px;
}

.help-banner-content .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin: 0px;
  background-color: #fff;
  border: none;
}

.help-banner-content .input-group input:focus {
  box-shadow: none;
}

.help-banner-content .input-group ::placeholder {
  color: #7A7A7A;
}

.help-banner-content .input-group .form-control {
  color: #000;
}


.help-banner-content h3 {
  font-size: 32px;
  font-family: var(--poppins);
  font-weight: 600;
}

.help-banner-content .input-group.mt-4 {
  height: 50px;
  width: 60%;
  margin: 0 auto;
}

.submit-btn {
  position: absolute !important;
  right: 7px;
  font-family: var(--poppins);
  font-weight: 600;
  text-transform: uppercase;
  background: linear-gradient(#FFA200, #FF8000);
  bottom: 5px;
  padding: 8px 22px;
  border-radius: 25px !important;
  border: none !important;
  z-index: 4 !important;
}

/* .help-banner-content h3 span {
  color: #00F5A0;
} */

.important-topics span {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}


.important-topics span img {
  max-width: 50%;
}

.important-sec {
  background-color: #242424;
  padding: 60px 0px;
}


.important-topics {
  background: #10394E;
  padding: 40px;
  border-radius: 15px;
  transition: .4s ease-in-out;
  height: 247px;
}

.important-topics:hover {
  transform: scale(1.02);
  transition: .4s ease-in-out;
}

.popular-see {
  background-color: #41ACD1;
  padding: 60px 0px;
}

.popular-video-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: var(--poppins);
}

.dot {
  display: inline-block;
  position: absolute;
  left: 34.5%;
  bottom: 26%;
}

.important-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: var(--poppins);
}

.player-box {
  background: #000;
  padding: 10px;
  border-radius: 10px;
}

.support-sec {
  padding: 60px 0px;
  background-color: #242424;
}

.support-content h6 {
  font-size: 25px;
  font-family: var(--poppins);
  color: #00F5A0;
}

.support-content h3 {
  font-size: 40px;
  font-family: var(--poppins);
}

.support-content p {
  font-size: 18px;
  width: 90%;
  font-family: var(--poppins);
}

.support-content button {
  background: linear-gradient(to right, #FFA200, #FFA200);
  color: #fff;
  padding: 7px 30px;
  border-radius: 30px;
  border: none;
}

.upgrade-menu .nav .nav-link:hover {
  background-color: #F68802;
}

.support-content button:hover {
  color: #F68802;
}

.support-content button:focus {
  background: #fff;
  color: #F68802;
}

.traning-sec {
  background-color: #232323;

  min-height: 100vh;
}

.popular-topics-content {
  background-color: #171717;
  padding: 20px 30px;
  min-height: 100vh;
}

.popular-topics-content .vidoebtn {
  background: #F68802;
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  color: #fff;
}

.popular-topics-box .nav {
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px 0px 20px 0px;
}

.popular-topics-box .nav .nav-link {
  color: #fff;
  padding-left: 40px;
}


.popular-topics-box .nav .nav-link.active {
  background-color: #F68802;
}



.integration-box .img-wrraper {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-box li {
  max-width: 120px;
  width: 100%;
  background-color: #000000;
  text-align: center;
  padding: 20px;
  margin: 0 12px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 40%), 0 0 20px 0 rgb(0 0 0 / 40%);
  display: block;
  color: #fff;
  cursor: pointer;
  transform: scale(1.05);
  transition: .4s all;
}

.integration-box li:hover {
  background: linear-gradient(180deg, #F68802, #F68802);
  box-shadow: 0 0 20px 0 #F68802, 0 0 20px 0 #F68802;
  -webkit-transform: scale(1.1) !important;
  transform: scale(1.1) !important;
  transition: .4s all;
}


.integration-box ul li a {
  color: #fff;
}


.integration-sec {
  background: #171717;
  padding: 120px 0px 60px;
}


.my-connection {
  background: #171717;
  padding: 60px 0px;
}

.connection-box {
  background: #000000;
  padding: 20px;
  border-radius: 10px;
}

.facebook-delete button {
  background: #F68802;
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.facebook-connection {
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.facebook-connect-account button {
  background: #F68802;
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #ffffff;
}

.youtube-connect {
  background: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.youtube-connect img {
  width: 215px;
}

.facebook-connect-account button:hover {
  color: #F68802;
}

.facebook-connect-account button:focus {
  background: #fff;
  color: #F68802;
}

.facebook-delete button:hover {
  color: #F68802;
}

.facebook-delete button:focus {
  background: #fff;
  color: #F68802;
}

.youtube-connect button:hover {
  color: #F68802 !important;
}

.youtube-connect button:focus {
  background: #fff;
  color: #F68802;
}

.youbtube-connection .youtube-connect button {
  background: #F68802;
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.webinar-sec {
  padding: 60px 0px;
  /*background: url('../images/webnibeimg.png') 50%/cover no-repeat;*/


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #F68802;
}


.webiner-content p {
  font-size: 18px;
  font-family: var(--poppins);
  color: #fff;
}

.webiner-content button {
  background: #F68802;
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #000;
  font-weight: 600;
}

.webiner-content button:hover {
  color: #F68802 !important;
}

.warraper {
  min-height: 100vh;
  background: #171717;
}


.upgrads-sec {
  padding: 120px 0px 30px;
  padding-top: 0;
  background: #171717;
}


.upgrade-menu {
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
}

.upgrade-menu .nav .nav-link {
  color: #fff;
  padding: 10px 40px;

}

.upgrade-menu .nav .nav-link.active {
  background-color: #F68802;

}

.upgrade-content {
  background-color: #000000;
  padding: 20px 30px;
  /* min-height: 100vh; */
}

.upgrade-box-content {
  background-color: #171717;
  border-radius: 10px;
  overflow: hidden;
}

.upgrade-heading h6 {
  background: #F68802;
  padding: 10px;
}

.upgrade-para {
  padding: 10px;
}

.upgrade-box-content button {
  background: #F68802;
  padding: 7px 30px;
  border-radius: 30px;
  border: none;
  color: #fff;
}


.upgrade-para button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.upgrade-para button:hover {
  color: #F68802;

}

.upgrade-para button:focus {
  background: #fff !important;
  color: #F68802 !important;
}

.upgrade-box-content button:hover {
  color: #F68802;
}

.upgrade-box-content button:focus {
  background: #fff !important;
  color: #F68802;
}

.tutorial-content .upgrade button {
  background: #F68802;
  padding: 10px 30px;
  border: none;
  color: #fff;
  border-radius: 30px;

}

.tutorial-content .upgrade button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 257px;
}

.tutorial-content .upgrade button:hover {
  color: #F68802;
}

.tutorial-content .upgrade button:focus {
  background: #fff;
  color: #F68802;
}


.modal .ecommercebox {
  max-width: 600px !important;
}


.ecommercebox .modal-body .form-group {
  width: 80%;
  margin: auto;
}

.ecommercebox .modal-body .modal-scrollbar {
  display: flex;
  align-items: center;
  justify-content: center;
}


.ecommercebox .modal-body .modal-scrollbar .form-group input {
  background: #1E1E1E;
  height: 50px;
}

.ecommercebox .modal-body .modal-scrollbar .form-group input:focus {
  border-color: #F68802;
  box-shadow: none;
}

.ecommercebox .modal-body .modal-scrollbar .form-group .form-control {
  color: #fff;
}

.ecommercebox .modal-body .modal-scrollbar {
  height: 40vh;
}



.ecommercebox .modal-body .modal-scrollbar .form-group button {
  background: #F68802;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: #fff;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:hover {
  color: #F68802;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:focus {
  background: #fff;
  color: #F68802;
}

.hide-table-padding .form-group {
  background-color: #1E1E1E;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}

.hide-table-padding .form-group:focus-within {
  border-color: #F68802;
}

.hide-table-padding .form-group label {
  font-size: 14px;
  color: #ffffffbd;
}

.brandonfocus:focus-within {
  border-color: #F68802;
  box-shadow: none;
}

.hide-table-padding .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.hide-table-padding .form-group input:focus {
  box-shadow: none;
}

.hide-table-padding .form-group .form-control {
  color: #fff !important;
  font-weight: 200;
}

.hide-table-padding ::placeholder {
  color: #6c757d;
}


.template-cover {
  background-image: #F68802;
  border-radius: 10px;
  padding-top: 1px;
}

.template-cover .template-img {
  border-radius: 10px;
  position: relative;
  margin: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}

.template-time {
  position: absolute;
  background-color: #fff;
  padding: 1px 10px;
  color: #000;
  top: 5px;
  left: 5px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
}

.template-names {
  padding: 0px 0px 5px;
}

.template-img:hover .ClipsReel-overlay {
  opacity: 1 !important;
  transition: .8s ease-in-out !important;
  clip-path: circle(70.7% at 50% 50%) !important;
}


/** Custom */
.my-account-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #F68802;
}

.hide-social {
  display: none !important;
}


.sweet-alert {
  color: #0C0C0C !important;
}

.modal {
  background: rgba(0, 0, 0, .7);
}

.show-modal {
  display: block !important;
}

.load-icon-help {
  color: #F68802;
  font-size: 30px;
}

.webinar-sec {
  padding: 60px 0px;
  background: url('../src/images/webnibeimg.png') 50%/cover no-repeat;


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-weight: 600;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #fff;
}


.webiner-content p {
  font-size: 18px;
  font-family: var(--poppins);
  color: #fff;
}

.webiner-content button {
  background: #FFA200;
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
  font-weight: 600;
}

.webiner-content button:hover {
  color: #FFA200 !important;
}

.accnt-mngmt-btn:hover {
  color: #FFA200 !important;
}

.upload-btn-wrapper .custom-file-input {
  position: absolute;
  top: 0;
}


.script-sec {
  padding: 120px 0px 60px;
  background-color: #262626;
  min-height: 100vh;
}

.script-sec .form-group label {
  color: #fff;
}

.script-sec .form-group input {
  background-color: #0D0D0D;
  height: 49px;
}

.script-sec .form-group input:focus {
  border-color: #F68802;
  box-shadow: none;
}

.script-sec .form-group textarea {
  background-color: #0D0D0D;
  border-color: #707070;

}

.script-sec .form-group textarea:focus {
  border-color: #F68802;
  box-shadow: none;
}

.script-sec .form-group .form-control {
  color: #fff;
}

.script-create-btn button {
  background: #F68802;
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a {
  background: #F68802;
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a:hover {
  color: #F68802;
}


.script-create-btn button:hover {
  color: #F68802;
}


.secarchicone-part {
  position: relative;
  animation: mymove 3s;
  animation-iteration-count: 5;
}

@keyframes mymove {
  from {
    transform: scale(0.09);
  }

  to {
    transform: scale(1.1);

  }
}


.fb-card .youtubeicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -30%);
  font-size: 30px;

}


.script-create-btn button:focus-within {
  color: #F68802 !important;
  background: #fff !important;
}

.active-dim {
  border: 1px solid #F68802;
}

.active-slide {
  border-color: #F68802 !important;
}

.modal-backdrop {
  position: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hide-box {
  display: none !important;
}

.story-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.preview-text {
  position: absolute;
  bottom: 0;
  padding: 20px;
}


/*color picker*/
.swatch {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

.swatch-color {
  width: 100%;
  height: 14px;
  border-radius: 2px;
}

.swatch-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.sketch-picker {
  position: absolute;
  z-index: 999;
}

.Affi-color-picker {
  position: absolute;
  top: -17px;

}

.Affi-color-picker-2 {
  position: absolute;
  top: -180px;

}

.templateModel {
  margin: auto;
  max-width: 450px;
  height: auto;
}


.templateModel .modal-body {
  height: auto !important;
  box-shadow: 0 0 5px rgba(255, 255, 255, .1);
}

.btn-custom {
  background: #F68802;
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.crop-icon-center {
  position: absolute;
  top: 50%;
  left: 50%;
}

.use-button {
  background: #F68802;
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.btn-custom:hover {
  background-color: white;
  color: #F68802;
}

.preview-text span div {
  display: inline;
}

.highlight-text {
  background-color: yellow;
}


.search-load-box ::-webkit-scrollbar {
  width: 5px;
}

.search-load-box ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.search-load-box ::-webkit-scrollbar-thumb {
  background: #F68802;
  border-radius: 10px;
  height: 10vh !important;
}

.scroll-bar ::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.scroll-bar ::-webkit-scrollbar-thumb {
  background: #F68802;
  border-radius: 10px;
  height: 10vh !important;
}

/* Infinite scroll */
.infinite-scroll-component {
  display: flex;
  flex-wrap: wrap;
  
}



.slide-active {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #F68802;
}

.slide-active i {
  color: #F68802;
  transform: scale(1.5);
}

.loader-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.delete-media {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  filter: drop-shadow(2px 4px 6px black);
  width: unset !important;
  height: unset !important;
}

.delete-media svg {
  position: absolute;
  right: 5px;
  top: 5px;
}

.delete-media i {
  color: #d20424;
}

.disable-click {
  pointer-events: none
}

.disable {
  background-color: gray !important;
}

.custom-anchor {
  text-decoration: underline !important;
  cursor: pointer;
}


/*  */
.container {
  max-width: 1500px;
}

@media (max-width: 1699px) {
  .container {
    max-width: 1200px;
  }
}

.pageOuter {
  min-height: calc(100vh - 57px);
  background-color: #15191E;
  padding-top: 90px;
  padding-bottom: 90px;
}

.pageHeading {
  border-bottom: 1px solid #A2A2A2;
  padding-top: 27px;
}

.pageHeading h4 {
  margin: 0;
  padding: 0 0 27px 0;
}

.vidForm {
  padding: 50px 0;
}

.vidForm .form-control {
  background-color: #15191e;
  color: #fff;
  padding: 12px 20px;
  border-radius: 10px;
  border: 1px solid #4b596b;
}

.vidForm .form-control:focus {
  border: 1px solid #F68802;
  outline: none !important;
  box-shadow: none !important;
}

.formLabel {
  display: block;
  color: #FFF;
}

.themebtn {
  background: #F68802;
  border-radius: 10px;
  border: none;
  height: 45px;
  color: #fff;

}

.themebtn:hover {
  color: #F68802;
}

.editorWrap img {
  max-width: 100%;
}

.editorWrap ul {
  margin: 0;
  padding: 0;
}

.editorWrap ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.myeditorWrap ul li{
  height: 110px !important;
  overflow: hidden;
  margin-bottom: 10px;
}
.myeditorWrap ul li div{
  height: 100%;
}
.lineTxt-wrap {
  position: relative;
}

.switch-single.abs {
  position: absolute;
  margin: 0;
  padding: 0;
  right: 0;
  top: 0;
}

.singleTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFF;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 8px;
}

.singleTitle svg {
  font-size: 24px;
}

.singleTitle span {
  color: #FFF;
  font-size: 20px;
}

.form-box .form-group.add {
  margin: 15px 0 0 0;
  position: relative;
  margin-right: 40px;
}

.form-box .form-group.add .form-control {
  height: 30px;
}

.form-box .form-group.add textarea.form-control {
  height: 80px;
}

.form-group.add .delIcon {
  position: absolute;
  right: -40px;
  top: 8px;
  font-size: 20px;
}

.voiceIcn-off {
  position: absolute;
  left: 0;
  top: 3px;
  font-size: 20px;
  color: #fff;
}

.voiceIcn {
  position: absolute;
  left: 0;
  top: 3px;
  color: #fff;
  font-size: 20px;
}

.groupDetails {
  position: relative;
  padding-right: 40px;
}

.groupDetails .delIcon {
  position: absolute;
  right: 0;
  top: 8px;
  font-size: 20px;
  color: #FFF;
}

.form-box .form-group select.form-control {
  background: #1E1E1E;
  border: none;
}

.form-box .form-group select.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-box .form-group .form-control.alt {
  height: 39px;
}

/*  */



/* Editor CSS */

.demoLink {
  display: inline-block;
  color: #FFF;
  font-weight: 600;
  padding: 14px 30px;
  border-radius: 50px;
  margin-top: 30px;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  background: #ffa200;
  /* background: -moz-linear-gradient(left,  #41acd1 0%, #ffa200 100%);
  background: -webkit-linear-gradient(left,  #41acd1 0%,#ffa200 100%);
  background: linear-gradient(to right,  #41acd1 0%,#ffa200 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#41acd1', endColorstr='#ffa200',GradientType=1 ); */
}

.demoLink:focus {
  border: none;
  box-shadow: none;
  outline: 0;
}

.demoLink .fa {
  margin-right: 10px;
}

.demoLink:hover {
  color: #FFF;
}

.roundInp {
  border-radius: 50px;
  background: #000;
  border: 1px solid #707070;
  font-size: 15px;
  padding: 6px 25px;
  color: #FFF;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  margin: 0 !important;
  padding: 0 !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #636363;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #F68802;
}

input:focus+.slider {
  box-shadow: 0 0 1px #F68802;
}

input:checked+.slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-title {
  font-size: 14px;
  padding-right: 10px;
  color: #6E6E6E;
  font-weight: 600;
}



.editor-left {
  background: #191919;
  height: calc(100vh - 166px);
  width: 450px;
  position: fixed;
  left: 0;
  top: 66px;
  color: #FFF;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px;
  z-index: 8;
  padding-bottom: 40px;
}

.editor-left p {
  font-size: 13px;
}

.editor-left::-webkit-scrollbar {
  width: 6px;
}

.editor-left::-webkit-scrollbar-thumb {
  background-color: #ffa200;
  border-radius: 10px;
}

.editor-right {
  width: 100%;
  padding: 66px 0 156px 450px;
  background: #2b2b2b;
  /* height: calc(100vh - 120px); */
  overflow-x: hidden;
  overflow-y: auto;
}

.campaignBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #000000;
  padding: 15px 50px;
}

.campaignBar .demoLink {
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  margin-left: 10px;
}

.campaignBar .demoLink img {
  margin-right: 10px;
}

.demoLink.red {
  background: red;
}

.campaignNav {
  background: #232323;
  padding: 12px 50px;
}

.campaignNav .nav {
  align-items: center;
  justify-content: center;
}

.campaignNav .nav-link {
  width: 80px;
  height: 64px;
  background: #414141;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF !important;
  margin: 0 7px;
  cursor: pointer;
}

.campaignNav .nav-link.active {
  background: #41acd1;
  /* background: -moz-linear-gradient(left,  #e512c9 0%, #5d00ff 100%);
  background: -webkit-linear-gradient(left,  #e512c9 0%,#5d00ff 100%);
  background: linear-gradient(to right,  #e512c9 0%,#5d00ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e512c9', endColorstr='#5d00ff',GradientType=1 ); */
}

.campaignNav .nav-link span {
  display: block;
  width: 100%;
  text-align: center;
}

.campaignNav .nav-link span img {
  height: 20px;
}

.campaignNav .nav-link span+span {
  font-size: 13px;
  padding-top: 2px;
  line-height: 16px;
}

.editorReel {
  background-color: #000000;
  padding: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
}

.editorReel-scroll::-webkit-scrollbar {
  height: 5px;
  -moz-height: 5px;
}

.editorReel-scroll::-webkit-scrollbar-thumb {
  -moz-background-color: #ffa200;
  background-color: #ffa200;
  border-radius: 10px;
  -moz-border-radius: 10px;
}

.editorReelwrap {
  position: relative;
}

.editorReel-scroll {
  overflow-x: auto !important;
  overflow-y: hidden;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.editorReel-scroll ul {
  display: flex;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  position: relative;
}

.editorReel-scroll ul li {
  display: inline-block;
  margin-left: 15px;
}

.editorReel-scroll ul li:first-child {
  margin-left: 0;
}

.editorReel-single {
  width: 150px;
  height: 80px;
  background: #101010;
  border: 2px solid #434343;
  border-radius: 10px;
  position: relative;
  color: #FFF;
}

.editorReel-single:hover {
  border-color: #ffa200;
}

.editorReel-dragmessage {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
}

.editorReel-single .delIcon {
  position: absolute;
  right: 3px;
  top: 4px;
  color: '#fff';
  font-size: 20px;
}

.editorReel-scroll ul li:hover .delIcon {
  color: #ffa200;
}

.editorReel-scroll ul li:hover .voiceIcn {
  color: #ffa200;
}

.editorReel-scroll ul li:hover .voiceIcn-off {
  color: #ffa200;
}

.addReelBt {
  position: absolute;
  right: -50px;
  top: 50%;
  margin-top: -17px;
  cursor: pointer;
}


.canvasEditor {
  max-width: 640px;
  margin: 0 auto;
  padding-top: 60px;
}

.canvasEditor-main {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  border: 2px solid #191919;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.canvasEditor-main iframe,
.canvasEditor-main canvas,
.canvasEditor-main video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.videoControll {
  height: 60px;
  padding: 0 25px;
  background: #000;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #FFF;
}

.videoControll-txt {
  height: 60px;
  display: flex;
  align-items: center;
}

.videoControll-txt span {
  font-size: 14px;
}

.videoControll-txt span+span {
  margin-left: 15px;
}

.videoControll-nav ul {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.videoControll-nav ul li {
  margin: 0 5px;
  font-size: 22px;
  cursor: pointer;
}

.videoControll-nav ul li .fa-play {
  margin-left: 3px;
}

.videoControll-nav ul li:hover {
  color: #B88AFF;
}

.videoControll-right {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 22px;
}

.story-block ul li {
  margin-top: 15px;
}

.story-block ul li:first-child {
  margin-top: 0;
}

.story-block-single {
  background: #242424;
  border: 1px solid #707070;
  border-radius: 12px;
  padding: 20px;
}

.story-block ul li:hover .story-block-single,
.story-block ul li.active .story-block-single {
  background: #41acd1;
  border: 1px solid #41acd1;
}

.inTabNav.nav {
  flex-wrap: nowrap;
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
}

.template-limitbar {
  position: relative;
  top: -18px;
  right: 21px;
  color: #fff;
}

.inTabNav.nav .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  border-left: 1px solid #1A1A1A;
}

.inTabNav.nav .nav-item:first-child {
  border-left: 0;
}

.inTabNav.nav .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #6D6D6D !important;
  font-size: 13px;
  padding: 15px 5px;
  text-align: center;
  border-radius: 0 !important;
}

.voice-over.nav .nav-link {
  height: 100%;

}

.inTabNav.nav .nav-link.active {
  color: #FFF !important;
  background: #ffa200 !important;
}

.inTabNav.nav .nav-item:first-child,
.inTabNav.nav .nav-item:nth-child(2),
.inTabNav.nav .nav-item:nth-child(3) {
  max-width: 82px;
}

.innertab {
  padding-top: 20px;
}

.tabSearch {
  position: relative;
}

.tabSearch input[type="text"] {
  width: 100%;
  border: none;
  background: #FFF;
  font-size: 14px;
  padding: 10px 15px;
  padding-left: 40px;
  border-radius: 10px;
}

.inp-icon {
  position: absolute;
  left: 15px;
  top: 9px;
  color: #979797;
}

.mediaList ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.mediaList ul li {
  margin-top: 15px;
  width: 100%;
  min-height: 1px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.mediaList ul li:first-child,
.mediaList ul li:nth-child(2) {
  margin-top: 0;
}

.mediaList-single {
  height: 110px;
  overflow: hidden !important;
  border: 1px solid #707070;
}
.mediaList-single .editorWrap img{
  height: 100% !important;
  object-fit: cover !important;
}

.innertab-scroll {
  margin-top: 20px;
  /* height: calc(100vh - 370px); */
  height: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -15px;
}

.innertab-scroll::-webkit-scrollbar {
  width: 6px;
}

.innertab-scroll::-webkit-scrollbar-thumb {
  background-color: #ffa200;
  border-radius: 10px;
}

.mediaImg {
  object-fit: cover;
  height: 100%;
}

.tabSearch .demoLink {
  padding-top: 8px;
  padding-bottom: 8px;
}


.inTabNav.full .nav-item {
  max-width: 100% !important;
}

.audioList-single {
  border-radius: 10px;
  overflow: hidden;
  background: #41acd1;
  /* background: -moz-linear-gradient(top,  #9a00ff 0%, #4d0080 100%);
background: -webkit-linear-gradient(top,  #9a00ff 0%,#4d0080 100%);
background: linear-gradient(to bottom,  #9a00ff 0%,#4d0080 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9a00ff', endColorstr='#4d0080',GradientType=0 ); */
}

.audioList-top {
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waveIcon {
  max-width: 75px;
}

.audio-time {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 11px;
  font-weight: 500;
}

.audioList-bottom {
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.audioList-bottom .fa {
  margin-right: 8px;
  font-size: 20px;
}

.tooltipsarrow>.tooltip-inner {
  background-color: #000 !important;
  color: #fff;

}

.tooltipsarrow.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: transparent !important;
}

.audioList-bottom p {
  line-height: 14px;
  margin: 0;
  padding: 0;
}

.innertab-scroll.alt {
  margin-top: 0;
  padding-top: 0;
  /* height: calc(100vh - 310px); */
}

.absInp {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.ai-voice-block {
  background: #303030;
  border-radius: 10px;
  padding: 10px;
}

.voice-block-top select {
  width: 100%;
  background: #000;
  color: #FFF;
  border-radius: 4px;
  border: 1px solid #707070;
  font-size: 13px;
  padding: 8px 5px;
}

.voice-block-top .row {
  margin-left: -5px;
  margin-right: -5px;
}

.voice-block-top [class*='col-'] {
  padding-left: 5px;
  padding-right: 5px;
}

.optDesc {
  display: block;
  text-align: center;
  font-size: 11px;
  color: #7D7D7D;
  padding-top: 5px;
}

.voice-block-bottom .demoLink {
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-flex;
  align-items: center;
  font-size: 15px;
}

.voice-block-bottom .demoLink img {
  margin-right: 10px;
}

.voice-block-mid {
  padding-top: 20px;
  padding-bottom: 10px;
}

.voice-block-mid textarea {
  background: #0C0C0C;
  border: 1px solid #707070;
  border-radius: 12px;
  width: 100%;
  height: 170px;
  font-size: 14px;
  color: #FFF;
  padding: 15px;
}

.add-block-single h2 {
  font-size: 16px;
  color: #FFF;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.add-block-main {
  background: #303030;
  border-radius: 10px;
  padding: 15px;
  margin-top: 15px;
}

.fileUpload {
  position: relative;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #171717;
  border: 1px dashed #707070;
  font-size: 14px;
  border-radius: 10px;
}

.fileUpload .fa {
  font-size: 20px;
  margin-right: 10px;
}

.fileUpload input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
}

.itemDisp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.itemDisp-left ul {
  margin: 0;
  padding: 0;
}

.itemDisp-left ul li {
  display: inline-block;
  width: 56px;
  height: 56px;
  background: #171717;
  border: 1px solid #686868;
  vertical-align: top;
  border-radius: 100%;
  margin-left: 10px;
}

.itemDisp-left ul li:first-child {
  margin-left: 0;
}

.uploadImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  padding: 1px;
  border: 1px solid white;
}

.add-block-single {
  margin-top: 30px;
}

.add-block-single:first-child {
  margin-top: 0;
}

.add-block-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-block-title a {
  display: inline-block;
  font-size: 13px;
  color: #FFF;
  background: #000000;
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 5px 15px;
}

.add-block-title a:hover {
  background: #ffa200;
  border: 1px solid #ffa200;
}

.itemDisp-right a {
  display: inline-block;
  font-size: 13px;
  color: #FFF;
  background: #000000;
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 5px 15px;
}

.itemDisp-right button {
  display: inline-block;
  font-size: 13px;
  color: #FFF;
  background: #000000;
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 5px 15px;
}

.itemDisp-right button:hover {
  background: #ffa200;
  border: 1px solid #ffa200;
}

.model-close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: transparent;
  color: white;
  border: none;
  font-size: 20px;
}


.itemDisp-right a:hover {
  background: #ffa200;
  border: 1px solid #ffa200;
}

.add-block-main h3 {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
}

.motionlist ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.motionlist ul li {
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.motionlist-single {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50px;
  background: #0C0C0C;
  border: 1px solid #0C0C0C;
  border-radius: 10px;
  font-size: 13px;
  color: #787878;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
}

.motionlist-single:hover,
.motionlist-single.active {
  border: 1px solid #ffa200;
}

.transition-single {
  position: relative;
  height: 80px;
  cursor: pointer;
  margin: 5px 0;
}

.transition-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.transition-txt {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.txt-format select {
  width: 100%;
  background: #0C0C0C;
  color: #FFF;
  border-radius: 4px;
  border: 1px solid #707070;
  font-size: 13px;
  padding: 8px 5px;
}

.txt-format .row {
  margin-left: -5px;
  margin-right: -5px;
}

.txt-format [class*='col-'] {
  padding-left: 5px;
  padding-right: 5px;
}

.txt-format h2 {
  font-size: 16px;
  font-weight: 600;
}

.txt-format input[type="text"] {
  width: 100%;
  background: #0C0C0C;
  color: #FFF;
  border-radius: 4px;
  border: 1px solid #707070;
  font-size: 13px;
  padding: 8px 5px;
}

.fontStyle-block {
  margin-top: 16px;
}

.font-format {
  background: #0C0C0C;
  border: 1px solid #707070;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 18px;
}

.font-format .fa {
  font-size: 16px;
}

.fontStyle-block-wrap {
  background: #303030;
  border-radius: 10px;
  padding: 15px;
  margin-top: 30px;
}

.fontStyle-block-wrap-2 {
  background: #303030;
  border-radius: 10px;
  margin-top: 15px;
}

.inpWrap {
  position: relative;
}

.colorValue {
  position: absolute;
  left: 6px;
  top: 5px;
  width: 27px;
  height: 27px;
  background: #FFF;
  border-radius: 5px;
}

span.label {
  display: block;
  text-align: center;
  color: #7D7D7D;
  font-size: 12px;
  font-weight: 600;
  padding-top: 5px;
}

.color-palette ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1px;
  margin-left: -1px;
}

.color-palette ul li {
  width: 100%;
  min-height: 1px;
  padding-right: 1px;
  padding-left: 1px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 9.09%;
  flex: 0 0 9.09%;
  max-width: 9.09%;
  margin-top: 2px;
}

.color-palette-single {
  height: 30px;
  background: #009DB0;
  border-radius: 2px;
}

.fontStyle-block-wrap .itemDisp {
  margin-top: 0;
}

/* .fontStyle-block-wrap .fontStyle-block{ margin-top: 0;} */

.itemDisp-right select {
  border: none;
  background: #171717;
  border-radius: 5px;
  color: #FFF;
  font-size: 14px;
  padding: 8px 5px;
  width: 200px;
}

.itemDisp-right select:focus {
  outline: 0;
  border: none;
  box-shadow: none;
}

.slidern {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #171717;
  outline: none;
  border-radius: 10px;
}

.slidern::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #41acd1;
  cursor: pointer;
  border-radius: 100%;
}

.slidern::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #41acd1;
  cursor: pointer;
}

.cateImg img {
  width: 42px;

}

.cateSingle p {
  text-align: center;
  font-size: 15px;
}

.cateSingle {
  width: 100%;
  background: #414141;
  border-radius: 9px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  color: #FFF;
  margin: 5px 0;
  cursor: pointer;
}

.cateSingle:hover {
  transform: scale(1.1) !important;
  transition: .4s linear;
}

.modalEdit .modal-content {
  margin: 0;
  width: 800px;
  max-width: 800px;
}

/* Editor CSS */

.vidForm-title {
  color: #FFF;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 24px;
  padding-bottom: 8px;
  margin-bottom: 30px;
}

.personal-details-box h6.lineTxt {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0 0 20px 0;
  margin: 0 0 30px 0;
  display: flex;
  align-items: center;
}

.personal-details-box h6.lineTxt img {
  width: 32px;
  margin-right: 8px;
}

.form-box textarea {
  background-color: transparent;
  border: none !important;
  padding-left: 0;
  height: 80px;
}

.form-box textarea:focus {
  background-color: transparent;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.img-box {
  position: absolute;
  width: 25%;
  height: 65px;
  border-radius: 7px;
  top: 35px;
  background: url('./images/dummy.jpg');
  right: 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  text-align: center;
}

.demo-box {
  position: absolute;
  width: 25%;
  height: 65px;
  border-radius: 7px;
  top: 11px;
  background: url('./images/video-dummy.png');
  right: 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  text-align: center;
}

.imgUp {
  position: relative;
  width: 62%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  margin-top: 7px;
  margin-bottom: 7px;
  color: #FFF;
  font-size: 13px;
}

.imgUp2 {
  height: 64px;
  margin-top: 32px;
  margin-bottom: 20px
}

.imgUp input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.imgUp svg {
  margin-right: 10px;
}

.pos {
  width: 250px;
  margin: 0 auto;
}

.pos ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.pos ul li {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.pos-single {
  width: 50px;
  height: 50px;
  margin: 10px auto;
  background: #171717;
  border: 1px solid #171717;
  border-radius: 5px;
  cursor: pointer;
}

.pos ul li.active .pos-single {
  border: 1px solid #41acd1;
}

.add-block-main .inPut {
  width: 100%;
  background: #0C0C0C;
  color: #FFF;
  border-radius: 4px;
  border: 1px solid #707070;
  font-size: 13px;
  padding: 8px 5px;
}


.randring-card {
  background-color: #fff;
  padding: 40px 20px;
  height: 192px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.align-item-center {
  justify-content: center !important;
  padding-top: 50px !important;
}

.progress-content span {
  color: gray;
  font-size: 12px;
}

.progress-content h5 {
  color: blue;
  font-size: 12px;
}

.progress-status span {
  color: blue;
  font-size: 16px;
  font-weight: 600;
}

.cards-spnniner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.progress-status h5 {
  color: gray;
  font-size: 12px;
}

.render-overlay {
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background: rgba(0, 0, 0, .8);
}

/* .row.altRow {
  margin-left: -25px !important;
  margin-right: -25px !important;
} */

.logo-image-set{
  height: 50px;
  max-width: 210px;
}
.editorWrap.myeditorWrap .edit-vid-btn li{
  height: auto !important;
}

