.editor-main {
  height: 100vh;
}

.icon-response li {
  width: 120px;
  height: 120px;
  margin: 0 10px;
  text-align: center;
  padding: 20px;
  display: block;
  color: #fff;
}

.icon-response .disable {
  background: none !important;
}

.icon-response li:hover {
  width: 120px;
  height: 120px;
  background-color: gray !important;
  text-align: center;
  box-shadow: 0 0 20px 0rgb(0 0 0/40%), 0 0 20px 0rgb(0 0 0/40%);
  border-radius: 50%;


}

/* publish select on click class name */

.icon-response li.select-social {
  width: 120px;
  height: 120px;
  background-color: #F68802 !important;
  text-align: center;
  box-shadow: 0 0 20px 0rgb(0 0 0/40%), 0 0 20px 0rgb(0 0 0/40%);
  border-radius: 50%;


}
.icon-response li {
  width: 120px;
  height: 120px;
  margin: 0 10px;
  text-align: center;
  padding: 20px;
  display: block;
  color: #fff;
}

.icon-response .disable {
  background: none !important;
}

.icon-response li:hover {
  width: 120px;
  height: 120px;
  background-color: gray !important;
  text-align: center;
  box-shadow: 0 0 20px 0rgb(0 0 0/40%), 0 0 20px 0rgb(0 0 0/40%);
  border-radius: 50%;


}

/* publish select on click class name */

.icon-response li.select-social {
  width: 120px;
  height: 120px;
  background-color: #F68802 !important;
  text-align: center;
  box-shadow: 0 0 20px 0rgb(0 0 0/40%), 0 0 20px 0rgb(0 0 0/40%);
  border-radius: 50%;


}
.icon-response li {
  width: 120px;
  height: 120px;
  margin: 0 10px;
  text-align: center;
  padding: 20px;
  display: block;
  color: #fff;
}

.icon-response .disable {
  background: none !important;
}

.icon-response li:hover {
  width: 120px;
  height: 120px;
  background-color: gray !important;
  text-align: center;
  box-shadow: 0 0 20px 0rgb(0 0 0/40%), 0 0 20px 0rgb(0 0 0/40%);
  border-radius: 50%;


}

/* publish select on click class name */

.icon-response li.select-social {
  width: 120px;
  height: 120px;
  background-color: #F68802 !important;
  text-align: center;
  box-shadow: 0 0 20px 0rgb(0 0 0/40%), 0 0 20px 0rgb(0 0 0/40%);
  border-radius: 50%;


}

.icon-response li a {
  color: #fff;
}




.icon-response .img-wrraper {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-response-span:hover .wrap-icon-image {
  width: 80px;
  height: 80px;
  /* background: red; */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 0px 5px;
  transition: .4s ease-in-out;
}


.icon-response-span .wrap-icon-image {
  width: 80px;
  height: 80px;
  /* background: red; */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: .4s ease-in-out;
}